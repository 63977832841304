import React from 'react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import { BaseDivProps } from 'src/types'

type Props = {
  enabled: boolean
} & BaseDivProps

function OnOrOff({ enabled, className }: Props) {
  return enabled ? (
    <div className={clsx('flex items-center gap-2 text-sm', className)}>
      <CheckCircleIcon className='text-success' width={18} />
      ON
    </div>
  ) : (
    <div className={clsx('flex items-center gap-2 text-sm', className)}>
      <XCircleIcon className='text-subtitle' width={18} />
      OFF
    </div>
  )
}

export default OnOrOff
