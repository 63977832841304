import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ArrowRightStartOnRectangleIcon, Bars3Icon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import GradientButton from 'src/components/buttons/GradientButton'
import IconButton from 'src/components/buttons/IconButton'
import BaseLink from 'src/components/links/BaseLink'
import BaseLinkButton from 'src/components/links/BaseLinkButton'

import { AppDispatch } from 'src/store'
import { logoutAction } from 'src/store/auth'

import { BaseDivProps } from 'src/types'

function Header({ className }: BaseDivProps) {
  const dispatch = useDispatch<AppDispatch>()
  const [openMenu, setOpenMenu] = useState(false)

  const logoutHandler = useCallback(() => {
    dispatch(logoutAction())
  }, [])

  const handleToggleMenu = useCallback(() => setOpenMenu((prev) => !prev), [])
  const handleCloseMenu = useCallback(() => setOpenMenu(false), [])

  return (
    <div className={clsx('sticky top-0 z-10 bg-mainBg py-4 shadow-2xl', className)}>
      <div className='m-auto flex items-center justify-between gap-8 px-2 sm:container xs:px-4 sm:justify-normal 2xl:px-6 3xl:px-8'>
        <div className='flex items-center gap-3'>
          <img className='h-[30px] w-[30px]' src='/favicon.ico' alt='logo' />
          <div className='gradient-text text-2xl font-bold tracking-widest'>MONITOR</div>
        </div>
        <div className='hidden flex-auto gap-6 sm:flex'>
          <BaseLink to='/'>Home</BaseLink>
          <BaseLink to='/settings'>Settings</BaseLink>
        </div>
        <GradientButton
          color='primary'
          size='sm'
          className='hidden rounded-lg sm:inline-flex'
          icon={<ArrowRightStartOnRectangleIcon className='mr-1 size-5 text-primary' />}
          onClick={logoutHandler}
        >
          Logout
        </GradientButton>
        <IconButton className='sm:hidden' onClick={handleToggleMenu}>
          <Bars3Icon />
        </IconButton>
      </div>
      <div
        className={clsx(
          'fixed bottom-0 left-0 right-0 top-[62px] hidden flex-col bg-black bg-opacity-90 sm:!hidden ',
          {
            '!flex': openMenu,
          },
        )}
        onClick={handleCloseMenu}
      >
        <div className='absolute left-0 right-0 top-0 z-[-1] flex flex-col gap-2 bg-mainBg'>
          <div className='hover:bg-white/5'>
            <BaseLink className='container mx-auto block p-2' to='/'>
              Home
            </BaseLink>
          </div>
          <div className='hover:bg-white/5'>
            <BaseLink className='container mx-auto block p-2' to='/settings'>
              Settings
            </BaseLink>
          </div>
          <div className='hover:bg-white/5'>
            <BaseLinkButton className='container mx-auto block p-2' onClick={logoutHandler}>
              Log out
            </BaseLinkButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
