import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Decimal from 'decimal.js-light'
import fromExponential from 'from-exponential'
import _ from 'lodash'

import errorHandler from 'src/libs/error.handler'
import { botTokenStorageKey, urlFromBot } from 'src/libs/helper'
import Storage from 'src/libs/storage'

import { PnlHistory } from 'src/providers/BotSocketProvider/BotSocket'

import {
  addBot,
  BotReq,
  BotRes,
  checkGunbotStatus,
  CustomStratEditors,
  deleteBot,
  getCustomStratEditors,
  getGunbotVersion,
  getLatestVersion,
  GunbotConfig,
  GunbotCoremem,
  loadBots,
  loadGunbotData,
  loginGunbot,
  logoutGunbot,
  saveGunbotConfig,
  startGunbot,
  stopGunbot,
  StratSettingValue,
  updateBot,
  upgradeGunbot,
} from './bots.api'
import { RootState } from '..'

export type DownloadStatus = {
  percent: string
  totalSize: number
  downloadedSize: number
}

export type BotsState = {
  bots: BotRes[]
  configs: {
    [url: string]: GunbotConfig
  }
  coremems: {
    [url: string]: GunbotCoremem
  }
  loadings: {
    [url: string]: boolean
  }
  pnlHistory: {
    [url: string]: PnlHistory
  }
  customStratEditors: {
    [url: string]: CustomStratEditors
  }
  upgrading: {
    [url: string]: DownloadStatus
  }
}

export const addBotAction = createAsyncThunk('bot/add', errorHandler(addBot))
export const loadBotsAction = createAsyncThunk('bot/loadBots', errorHandler(loadBots))
export const deleteBotAction = createAsyncThunk('bot/deleteBot', errorHandler(deleteBot))
export const updateBotAction = createAsyncThunk('bot/updateBot', errorHandler(updateBot))

export const checkGunbotStatusAction = createAsyncThunk(
  'bot/checkGunbotStatus',
  errorHandler(checkGunbotStatus),
)
export const loginGunbotAction = createAsyncThunk('bot/loginGunbot', errorHandler(loginGunbot))
export const logoutGunbotAction = createAsyncThunk('bot/logoutGunbot', errorHandler(logoutGunbot))
export const loadGunbotDataAction = createAsyncThunk(
  'bot/loadGunbotData',
  errorHandler(loadGunbotData),
)
export const startGunbotAction = createAsyncThunk('bot/startGunbot', errorHandler(startGunbot))
export const stopGunbotAction = createAsyncThunk('bot/stopGunbot', errorHandler(stopGunbot))
export const saveGunbotConfigAction = createAsyncThunk(
  'bot/saveGunbotConfig',
  errorHandler(saveGunbotConfig),
)
export const getCustomStratEditorsAsync = createAsyncThunk(
  'bot/getCustomStratEditors',
  errorHandler(getCustomStratEditors),
)

export const upgradeGunbotAction = createAsyncThunk(
  'bot/upgradeGunbot',
  errorHandler(upgradeGunbot),
)

export const getLatestVersionAction = createAsyncThunk(
  'bot/getLatestVersion',
  errorHandler(getLatestVersion),
)
export const getGunbotVersionAction = createAsyncThunk(
  'bot/getGunbotVersion',
  errorHandler(getGunbotVersion),
)

const initialState: BotsState = {
  bots: [],
  configs: {},
  coremems: {},
  loadings: {},
  pnlHistory: {},
  customStratEditors: {},
  upgrading: {},
}

const prevConfigs: {
  [url: string]: GunbotConfig
} = {}

const botsSlice = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    updateBotAlive(state, { payload }: { payload: { id: number; alive: boolean } }) {
      const bot = state.bots.find((bot) => bot.id === payload.id)
      if (bot) {
        bot.alive = payload.alive
        if (bot.alive) {
          const url = urlFromBot(bot)
          delete state.upgrading[url]
        }
      }
    },
    togglePair(
      state,
      {
        payload: { url, exchange, pair, enabled },
      }: { payload: { url: string; exchange: string; pair: string; enabled: boolean } },
    ) {
      state.configs[url].pairs[exchange][pair].enabled = enabled
    },
    setPairOverride(state, action) {
      const { url, exchange, pair, name, value } = action.payload as {
        url: string
        exchange: string
        pair: string
        name: string
        value: StratSettingValue
      }
      state.configs[url].pairs[exchange][pair].override[name] = value
    },
    removePairOverride(state, action) {
      const { url, exchange, pair, name } = action.payload as {
        url: string
        exchange: string
        pair: string
        name: string
      }
      delete state.configs[url].pairs[exchange][pair].override[name]
    },
    setPnlHistory(state, action: { payload: { url: string; data: PnlHistory } }) {
      const url: string = action.payload.url
      state.pnlHistory[url] = action.payload.data
    },
    removePair(state, action) {
      const { exchange, pair, url } = action.payload as {
        exchange: string
        pair: string
        url: string
      }
      const pairs = state.configs[url].pairs[exchange]
      if (Object.keys(pairs)) {
        delete state.configs[url].pairs[exchange]
      } else {
        delete state.configs[url].pairs[exchange][pair]
      }
    },
    removeBot(state, action) {
      const bot = action.payload as BotReq
      state.bots = state.bots.filter((_bot) => urlFromBot(_bot) !== urlFromBot(bot))
    },
    updateGunbotUpgradingStatus(state, action) {
      state.upgrading[action.payload.url] = action.payload.data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBotAction.fulfilled, (state, action) => {
        state.bots.push(action.payload)
      })
      .addCase(loadBotsAction.fulfilled, (state, action) => {
        state.bots = action.payload
      })
      .addCase(updateBotAction.fulfilled, (state, action) => {
        const bot = state.bots.find((_bot) => _bot.id === action.payload.id)
        if (bot) {
          bot.name = action.payload.name
        }
      })
      .addCase(deleteBotAction.fulfilled, (state, action) => {
        const bot = state.bots.find((bot) => bot.id === action.payload.id)
        if (bot) {
          state.bots = state.bots.filter((bot) => bot.id !== action.payload.id)
          const url = urlFromBot(bot)
          delete state.configs[url]
          delete state.coremems[url]
          delete state.pnlHistory[url]
          delete prevConfigs[url]
          Storage.remove(botTokenStorageKey(url))
        }
      })
      .addCase(checkGunbotStatusAction.fulfilled, (state, action) => {
        const bot = state.bots.find((bot) => bot.id === action.meta.arg.id)
        if (bot) {
          bot.loggedIn = action.payload.status === 'success'
          bot.isRegistered = action.payload.isRegistered
          bot.isTwoFA = action.payload.isTwoFA
        }
      })
      .addCase(loginGunbotAction.fulfilled, (state, action) => {
        const bot = state.bots.find((bot) => bot.id === action.meta.arg.bot.id)
        if (bot) {
          bot.token = action.payload.token
          Storage.set(botTokenStorageKey(urlFromBot(bot)), 'Bearer ' + bot.token)
        }
      })
      .addCase(logoutGunbotAction.fulfilled, (state, action) => {
        const bot = state.bots.find((bot) => bot.id === action.meta.arg.id)
        if (bot) {
          bot.token = ''
          Storage.remove(botTokenStorageKey(urlFromBot(bot)))
        }
      })
      .addCase(loadGunbotDataAction.fulfilled, (state, action) => {
        if (action.payload.status === 'error') return state

        const oldBot = state.bots.find((bot) => bot.id === action.meta.arg.bot.id)
        if (oldBot) {
          const url = urlFromBot(oldBot)
          let isUpdatedConfig = false
          const prevConfig = state.configs[url] || {}

          let config: { [key: string]: unknown } = _.cloneDeep(prevConfig)
          if (action.payload.processLoadedConfig) {
            isUpdatedConfig = true
            config = action.payload.processLoadedConfig
            oldBot.start = action.payload.processLoadedConfig.GUI.start
            let pairCount = 0
            const pairs = action.payload.processLoadedConfig.pairs
            Object.keys(pairs).forEach((exchange) => {
              pairCount += Object.keys(pairs[exchange]).length
            })
            oldBot.pairCount = pairCount
          }
          if (action.payload.processLoadedConnectedExchanges) {
            isUpdatedConfig = true
            config.exchanges = action.payload.processLoadedConnectedExchanges
          }
          if (action.payload.processLoadedIMAP) {
            isUpdatedConfig = true
            config['imap_listener'] = action.payload.processLoadedIMAP
          }
          if (action.payload.processLoadedWEBHOOKS) {
            isUpdatedConfig = true
            config.webhooks = action.payload.processLoadedWEBHOOKS
          }

          if (isUpdatedConfig && !_.isEqual(config, prevConfig)) {
            state.configs[url] = config as GunbotConfig
            prevConfigs[url] = _.cloneDeep(config) as GunbotConfig
          }

          if (action.payload.processLoadedCorememData) {
            state.coremems[url] = {
              memory: action.payload.processLoadedCorememData.memory,
            }
          }
        }
      })
      .addCase(startGunbotAction.fulfilled, (state, action) => {
        if (action.payload.status === 'error') return state
        const url = urlFromBot(action.meta.arg)
        state.configs[url] = action.payload
      })
      .addCase(stopGunbotAction.fulfilled, (state, action) => {
        if (action.payload.status === 'error') return state
        const url = urlFromBot(action.meta.arg)
        state.configs[url] = action.payload
      })
      .addCase(saveGunbotConfigAction.pending, (state, action) => {
        const url = action.meta.arg
        state.loadings[url] = true
      })
      .addCase(saveGunbotConfigAction.fulfilled, (state, action) => {
        const url = action.meta.arg
        const oldConfig = state.configs[url]
        prevConfigs[url] = _.cloneDeep({ ...oldConfig }) as GunbotConfig
        state.loadings[url] = false
      })
      .addCase(saveGunbotConfigAction.rejected, (state, action) => {
        const url = action.meta.arg
        state.loadings[url] = false
      })
      .addCase(getCustomStratEditorsAsync.fulfilled, (state, action) => {
        if (action.payload.status === 'success') {
          const url = action.meta.arg
          state.customStratEditors[url] = action.payload.templates
        }
      })
      .addCase(getGunbotVersionAction.fulfilled, (state, action) => {
        const url = action.meta.arg
        const bot = state.bots.find((bot) => urlFromBot(bot) === url)
        if (bot) {
          bot.version = action.payload
        }
      })
  },
})

const botsReducer = botsSlice.reducer
export default botsReducer

export const {
  updateBotAlive,
  togglePair,
  setPnlHistory,
  removePair,
  removeBot,
  setPairOverride,
  removePairOverride,
  updateGunbotUpgradingStatus,
} = botsSlice.actions

export const selectBots = (state: RootState) => state.bots.bots
export const selectGunbotConfig = (url: string) => (state: RootState) =>
  state.bots.configs[url] || {}

export const selectGunbotConfigPairs = (url: string) => (state: RootState) =>
  selectGunbotConfig(url)(state).pairs

export const selectGunbotConfigExchanges = (url: string) => (state: RootState) =>
  selectGunbotConfig(url)(state).exchanges

export const selectGunbotConfigExchange = (url: string, exchange: string) => (state: RootState) =>
  selectGunbotConfigExchanges(url)(state)[exchange] || {}

export const selectGunbotMarketType = (url: string, exchange: string) => (state: RootState) =>
  selectGunbotConfigExchange(url, exchange)(state).marketType || 'spot'

export const selectGunbotStrategies = (url: string) => (state: RootState) =>
  selectGunbotConfig(url)(state).strategies || {}

export const selectGunbotStrategy = (url: string, strategy: string) => (state: RootState) =>
  selectGunbotStrategies(url)(state)[strategy] || {}

export const selectGunbotPairConfig =
  (url: string, exchange: string, pair: string) => (state: RootState) =>
    selectGunbotConfig(url)(state).pairs?.[exchange]?.[pair] || {}

export const selectGunbotPairOverride =
  (url: string, exchange: string, pair: string) => (state: RootState) =>
    selectGunbotPairConfig(url, exchange, pair)(state)?.override || {}

export const selectGunbotCoremem = (url: string) => (state: RootState) => state.bots.coremems[url]
export const selectGunbotPairsData = (url: string) => (state: RootState) =>
  selectGunbotCoremem(url)(state)?.memory || {}
export const selectIsChangedGunbotConfig = (url: string) => (state: RootState) => {
  const config = selectGunbotConfig(url)(state)
  const prevConfig = prevConfigs[url] || {}

  return !_.isEqual(config, prevConfig)
}
export const selectIsGunbotLoading = (url: string) => (state: RootState) => {
  return state.bots.loadings[url]
}
export const selectAllPnlHistory = (state: RootState) => state.bots.pnlHistory
export const selectPnlHistory = (url: string) => (state: RootState) =>
  state.bots.pnlHistory[url] || {
    dailyHistory: [],
    pnlsPerPair: [],
    detailedPerPair: [],
    today: { pnl: 0 },
    yesterday: { pnl: 0 },
    weekAgo: { pnl: 0 },
    monthAgo: { pnl: 0 },
    yearAgo: { pnl: 0 },
    lastYear: { pnl: 0 },
    unit: 'USDT',
  }

export const selectCustomStratEditors = (url: string) => (state: RootState) =>
  state.bots.customStratEditors[url]

export const selectGunbotAllAnalysis = (state: RootState) => {
  let allROI = new Decimal(0)
  let allRR = new Decimal(0)
  let allCP = new Decimal(0)
  let numOfRR = 0
  let numOfCP = 0
  let numOfBots = 0
  // Loop through each bot's core memory
  _.each(state.bots.coremems, ({ memory }) => {
    _.each(memory, ({ gunbotROI = 0, riskReward, CUE }) => {
      if (!_.isNil(gunbotROI) && !_.isNaN(Number(gunbotROI))) {
        allROI = allROI.add(Number(gunbotROI))
        numOfBots += 1
      }
      if (!_.isNil(riskReward) && !_.isNaN(Number(riskReward))) {
        allRR = allRR.add(Number(riskReward))
        numOfRR++
      }
      if (!_.isNil(CUE) && !_.isNaN(Number(CUE))) {
        allCP = allCP.add(Number(CUE))
        numOfCP++
      }
    })
  })

  // If there are no bots, set the divisor to 1 to avoid division by zero
  if (numOfRR === 0) numOfRR = 1
  if (numOfCP === 0) numOfCP = 1
  return {
    allROI: +fromExponential(allROI.dividedBy(numOfBots).toFixed(2)),
    allRR: +fromExponential(allRR.dividedBy(numOfRR).toFixed(2)),
    allCP: +fromExponential(allCP.dividedBy(numOfCP).toFixed(2)),
  }
}

export const selectGunbotUpgradingStatus = (state: RootState) => state.bots.upgrading
