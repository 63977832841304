import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PauseIcon, PlayIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ChartBarIcon, PencilSquareIcon, StopIcon } from '@heroicons/react/24/solid'
import Decimal from 'decimal.js-light'
import fromExponential from 'from-exponential'
import _ from 'lodash'

import { numFormatter, urlFromBot } from 'src/libs/helper'
import useScreen from 'src/hooks/useScreen'

import { useGunbotAuth } from 'src/providers/BotLoginProvider'
import BaseButton from 'src/components/buttons/BaseButton'
import GradientButton from 'src/components/buttons/GradientButton'
import IconButton from 'src/components/buttons/IconButton'
import BaseDialog from 'src/components/dialogs/BaseDialog'
import BaseTable, { Column } from 'src/components/tables/BaseTable'
import GradientWrapper from 'src/components/wrappers/GradientWrraper'
import TableDetailPanelOfPair from 'src/pages/Home/components/TableDetailPanelOfPair'

import { AppDispatch } from 'src/store'
import {
  getCustomStratEditorsAsync,
  removePair,
  selectCustomStratEditors,
  selectGunbotConfigPairs,
  selectGunbotPairsData,
  selectPnlHistory,
  togglePair,
} from 'src/store/bots'
import { BotRes } from 'src/store/bots/bots.api'

import { SaveChangesBtn } from './BotsTableComponent'

import { BaseDivProps } from 'src/types'

type Props = {
  url: string
  rowIndex: number
  bot: BotRes
  onEditBot: (bot: BotRes) => void
  onDeleteBot: (bot: BotRes) => void
  onStartGunbot: (bot: BotRes) => void
  onStopGunbot: (bot: BotRes) => void
} & BaseDivProps

export type PairInfo = {
  exchange: string
  pair: string
  strategy: string
  baseBalance?: number
  quoteBalance?: number
  todayPnl?: number
  yearPnl?: number
  enabled: boolean
  unrealPnl: number
  gunbotROI: number
  riskReward: number
  MDD: number
  dailypnl: number
  bagvalue: number
  CUE: number
  url: string
}

function SettingDetail({ url, bot, onEditBot, onDeleteBot, onStopGunbot, onStartGunbot }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const handleGunbotAuth = useGunbotAuth()
  const greaterThan2md = useScreen('2md')
  const customStratEditors = useSelector(selectCustomStratEditors(url), _.isEqual)
  const configPairs = useSelector(selectGunbotConfigPairs(url), _.isEqual)
  const pairsData = useSelector(selectGunbotPairsData(url), _.isEqual)
  const numOfRunning = Object.keys(pairsData).length
  const pnlHistory = useSelector(selectPnlHistory(url), _.isEqual)
  const { today, yesterday, weekAgo, monthAgo, yearAgo, unit } = pnlHistory
  const [chartUrl, setChartUrl] = useState('')

  const todayGrow = new Decimal(today.pnl).minus(yesterday.pnl).toNumber()
  const todayGrowPercent =
    yesterday.pnl !== 0
      ? +(((today.pnl - yesterday.pnl) * 100) / Math.abs(yesterday.pnl)).toFixed(1)
      : 0

  const pairs = useMemo(() => {
    const pairs: PairInfo[] = []
    if (configPairs) {
      Object.keys(configPairs).forEach((exchange) => {
        const configExchangePairs = configPairs[exchange]
        Object.keys(configExchangePairs).forEach((pair) => {
          const key = `${exchange}/${pair}`
          const pairData = pairsData[key]
          const todayPnl = today.perPair && today.perPair[key] ? today.perPair[key].pnl : undefined
          const yearPnl =
            yearAgo.perPair && yearAgo.perPair[key] ? yearAgo.perPair[key].pnl : undefined
          const configPair = configExchangePairs[pair]
          pairs.push({
            exchange,
            pair,
            strategy: configPair.strategy,
            todayPnl,
            yearPnl,
            enabled: configPair.enabled,
            baseBalance: pairData?.baseBalance,
            quoteBalance: pairData?.quoteBalance,
            unrealPnl: pairData?.unreal_pnl,
            gunbotROI: pairData?.gunbotROI,
            riskReward: pairData?.riskReward,
            MDD: pairData?.MDD,
            CUE: pairData?.CUE,
            url,
            dailypnl: pairData?.dailypnl,
            bagvalue: pairData?.bagvalue,
          })
        })
      })
    }
    return pairs
  }, [configPairs, pairsData, today.perPair, yearAgo.perPair])

  const [selectedPairData, setSelectedPairData] = useState<PairInfo>()

  const onTogglePair = useCallback(
    (exchange: string, pair: string, enabled: boolean, url: string) => {
      dispatch(togglePair({ exchange, url, pair, enabled }))
    },
    [],
  )

  const onViewChart = useCallback((exchange: string, pair: string, url: string) => {
    setChartUrl(`${url}/chart?pair=${pair}&exchange=${exchange}`)
  }, [])
  
  const onDeletePair = useCallback((exchange: string, pair: string, url: string) => {
    dispatch(removePair({ exchange, pair, url }))
  }, [])

  const gotoBot = useCallback(() => {
    window.open(url, '_blank')
  }, [url])

  const handleCloseOverridePairDialog = useCallback(() => {
    setSelectedPairData(undefined)
  }, [])

  const columns = useMemo(() => {
    const columns: Column<PairInfo>[] = [
      {
        label: 'Exchange',
        field: 'exchange',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4 font-thin',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4',
      },
      {
        label: 'Pair',
        field: 'pair',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4 font-thin',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4',
        render: ({ exchange, pair }) => (
          <div className="flex items-center justify-between">
            <span>{pair}</span>
            <IconButton color='success' onClick={() => onViewChart(exchange, pair, url)}>
              <ChartBarIcon />
            </IconButton>
          </div>
        ),
      },
      {
        label: 'Today PnL',
        field: 'todayPnl',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center font-thin',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center',
        render: (pair) => {
          if (_.isNil(pair.todayPnl) || isNaN(Number(pair.todayPnl))) return '-'
          return fromExponential(+pair.todayPnl?.toFixed(8))
        },
      },
      {
        label: 'Year Pnl',
        field: 'yearPnl',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center font-thin',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center',
        render: (pair) => {
          if (_.isNil(pair.yearPnl)|| isNaN(Number(pair.yearPnl))) return '-'
          return fromExponential(+pair.yearPnl.toFixed(8))
        },
      },
      {
        label: 'uPnl',
        field: 'unrealPnl',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center font-thin',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center',
        render: (pair) => {
          if (_.isNil(pair.unrealPnl)|| isNaN(Number(pair.unrealPnl))) return '-'
          const pnl = Number(pair.unrealPnl) // Ensure it's a number
          return fromExponential(pnl.toFixed(8))
        },
      },
      {
        label: 'ROI%',
        field: 'gunbotROI',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center font-thin',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center',
        render: (pair) => {
          if (_.isNil(pair.gunbotROI) || isNaN(Number(pair.gunbotROI))) return '-'
          const rolY = Number(pair.gunbotROI) // Ensure it's a number
          return fromExponential(rolY.toFixed(2))
        },
      },
      {
        label: 'Risk/Reward',
        field: 'riskReward',
        headerClass: 'py-2 px-4 text-center font-thin',
        cellClass: 'py-2 px-4 text-center',
        render: (pair) => {
          if (_.isNil(pair.riskReward)|| isNaN(Number(pair.riskReward))) return '-'
          const rolY = Number(pair.riskReward) // Ensure it's a number
          return fromExponential(rolY.toFixed(2))
        },
      },
      {
        label: 'Max DrawDown',
        field: 'MDD',
        headerClass: 'py-2 px-4 text-center font-thin',
        cellClass: 'py-2 px-4 text-center',
        render: (pair) => {
          if (_.isNil(pair.MDD)|| isNaN(Number(pair.MDD))) return '-'
          const rolY = Number(pair.MDD) // Ensure it's a number
          return fromExponential(rolY.toFixed(2))
        },
      },
      {
        label: 'Daily pnl/1k',
        field: 'dailypnl',
        headerClass: 'py-2 px-4 text-center font-thin',
        cellClass: 'py-2 px-4 text-center',
        render: (pair) => {
          if (_.isNil(pair.dailypnl)|| isNaN(Number(pair.dailypnl))) return '-'
          const rolY = Number(pair.dailypnl) // Ensure it's a number
          return fromExponential(rolY.toFixed(2))
        },
      },
      {
        label: 'Base Balance',
        field: 'baseBalance',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center font-thin',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center',
        render: (pair) => {
          if (_.isNil(pair.baseBalance)|| isNaN(Number(pair.baseBalance))) return '-'
          return fromExponential(Number(pair.baseBalance).toFixed(8))
        },
      },
      {
        label: 'Quote Balance',
        field: 'quoteBalance',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center font-thin',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center',
        render: (pair) => {
          if (_.isNil(pair.quoteBalance)|| isNaN(Number(pair.quoteBalance))) return '-'
          return fromExponential(Number(pair.quoteBalance).toFixed(8))
        },
      },
      {
        label: 'Bag Value',
        field: 'bagvalue',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center font-thin',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center',
        render: (pair) => {
          if (_.isNil(pair.bagvalue)|| isNaN(Number(pair.bagvalue))) return '-'
          return fromExponential(Number(pair.bagvalue).toFixed(8))
        },
      },
      {
        label: 'On/Off',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center font-thin',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4 text-center',
        render: ({ enabled, exchange, pair }) => {
          return enabled ? (
            <IconButton
              size='md'
              color='warning'
              onClick={() => onTogglePair(exchange, pair, false, url)}
            >
              <PauseIcon />
            </IconButton>
          ) : (
            <IconButton
              size='md'
              color='success'
              onClick={() => onTogglePair(exchange, pair, true, url)}
            >
              <PlayIcon />
            </IconButton>
          )
        },
      },
      {
        label: 'Actions',
        render: ({ exchange, pair }) => (
          <>
           <IconButton color='error' onClick={() => onDeletePair(exchange, pair, url)}>
              <TrashIcon />
            </IconButton>
          </>
        ),
        headerClass: 'text-center py-3 text-center font-thin',
        cellClass: 'max-w-[100px] text-center py-2',
      },
    ]
    return columns
  }, [url])

  useEffect(() => {
    if (!customStratEditors) {
      dispatch(getCustomStratEditorsAsync(url))
    }
  }, [customStratEditors, url])

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex items-center justify-between pb-4 sm:hidden'>
        <h5 className='hidden xs:block'>{bot.name}</h5>
        <div className='flex w-full items-center justify-end gap-4 xs:w-auto'>
          <SaveChangesBtn url={urlFromBot(bot)} />
          {bot.loggedIn ? (
            <BaseButton
              className='w-fit text-nowrap rounded-md'
              color='error'
              size='xs'
              fontSize='2xs'
              variant='outlined'
              disabled={!bot.alive}
              onClick={() => handleGunbotAuth(bot, false)}
            >
              Log out
            </BaseButton>
          ) : (
            <BaseButton
              className='w-fit text-nowrap rounded-md'
              color='primary'
              size='xs'
              fontSize='2xs'
              variant='outlined'
              disabled={!bot.alive}
              onClick={() => handleGunbotAuth(bot, true)}
            >
              Log in
            </BaseButton>
          )}
          <div className='flex-auto xs:hidden'></div>
          {bot.start ? (
            <IconButton
              size='md'
              color='error'
              disabled={!bot.loggedIn}
              onClick={() => onStopGunbot(bot)}
            >
              <StopIcon />
            </IconButton>
          ) : (
            <IconButton
              size='md'
              color='success'
              disabled={!bot.loggedIn}
              onClick={() => onStartGunbot(bot)}
            >
              <PlayIcon />
            </IconButton>
          )}
          <IconButton color='warning' className='min-h-5 min-w-5' onClick={() => onEditBot(bot)}>
            <PencilSquareIcon />
          </IconButton>
          <IconButton color='error' onClick={() => onDeleteBot(bot)} className='min-h-5 min-w-5'>
            <TrashIcon />
          </IconButton>
        </div>
      </div>
      <div className='mb-3 grid gap-3 2md:grid-cols-6 xl:grid-cols-5'>
        <div className='rounded-lg border border-white/5 p-4 shadow-sm 2md:col-span-2'>
          <h6 className='gradient-text inline font-medium'>Todays profit and loss</h6>
          <div className='text-sm text-subtitle'>
            Total <span className='text-white'>{numOfRunning}</span> pairs running
          </div>
          <h6 className='my-3 text-white'>
            {numFormatter(today.pnl)} {unit}
          </h6>
          <GradientButton
            size='sm'
            border={2}
            className='rounded-lg !font-medium'
            onClick={gotoBot}
          >
            Go to Bot
          </GradientButton>
        </div>
        <div className='flex min-h-[159px] flex-col rounded-lg border border-white/5 p-4 shadow-sm 2md:col-span-4 xl:col-span-3'>
          <div className='flex items-center gap-2'>
            <h6 className='gradient-text font-medium'>Pnl Statistics</h6>
          </div>
          <div className='mt-1 text-sm text-subtitle'>
            Today {todayGrowPercent >= 0 ? 'already' : 'still'}{' '}
            <span className='text-white'>
              {numFormatter(todayGrow)} {unit}
            </span>{' '}
            {todayGrowPercent >= 0 ? 'better than yesterday 😎' : 'below yesterday 😥'}
          </div>
          <div className='mt-8 grid gap-2 xs:grid-cols-2 md:grid-cols-4'>
            <div className='col-span-1 flex gap-3'>
              <GradientWrapper className='inline-flex h-12 w-12 items-center justify-center rounded-md font-bold text-primary'>
                D
              </GradientWrapper>
              <div className='flex flex-col justify-between py-0.5 text-xs text-subtitle'>
                <span className='text-xs'>Yesterday PnL</span>
                <span>
                  <span className='text-sm text-title'>{numFormatter(yesterday.pnl)}</span> {unit}
                </span>
              </div>
            </div>
            <div className='col-span-1 flex gap-3'>
              <GradientWrapper className='inline-flex h-12 w-12 items-center justify-center rounded-md font-bold text-primary'>
                W
              </GradientWrapper>
              <div className='flex flex-col justify-between py-0.5 text-xs text-subtitle'>
                <span className='text-xs'>Week PnL</span>
                <span>
                  <span className='text-sm text-title'>{numFormatter(weekAgo.pnl)}</span> {unit}
                </span>
              </div>
            </div>
            <div className='col-span-1 flex gap-3'>
              <GradientWrapper className='inline-flex h-12 w-12 items-center justify-center rounded-md font-bold text-primary'>
                M
              </GradientWrapper>
              <div className='flex flex-col justify-between py-0.5 text-xs text-subtitle'>
                <span className='text-xs'>Month PnL</span>
                <span>
                  <span className='text-sm text-title'>{numFormatter(monthAgo.pnl)}</span> {unit}
                </span>
              </div>
            </div>
            <div className='col-span-1 flex gap-3'>
              <GradientWrapper className='inline-flex h-12 w-12 items-center justify-center rounded-md font-bold text-primary'>
                Y
              </GradientWrapper>
              <div className='flex flex-col justify-between py-0.5 text-xs text-subtitle'>
                <span className='text-xs'>Year PnL</span>
                <span>
                  <span className='text-sm text-title'>{numFormatter(yearAgo.pnl)}</span> {unit}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='-mb-1 mt-2 pl-4'>
        <h6 className='gradient-text inline-block font-medium'>Pairs</h6>
      </div>
      {greaterThan2md ? (
        <BaseTable
          columns={columns}
          data={pairs}
          className='border border-white/5'
          DetailedPanel={TableDetailPanelOfPair}
          fullDetailedPanel
        />
      ) : (
        <div className='grid gap-4 sm:grid-cols-2 md:grid-cols-3'>
          {selectedPairData && (
            <BaseDialog
              className='w-full rounded-lg'
              open={!!selectedPairData}
              onClose={handleCloseOverridePairDialog}
            >
              <TableDetailPanelOfPair rowData={selectedPairData} />
            </BaseDialog>
          )}
          {pairs.map((pairData, index) => (
            <div
              key={index}
              className='grid grid-cols-2 gap-1 rounded-lg border border-white/5 p-4 text-xs shadow-sm'
            >
              <div className='mb-2 flex flex-col gap-1 text-md'>
                <span className='font-bold'>{pairData.pair}</span>
                <span className='text-sm text-subtitle2 first-letter:uppercase'>
                  {pairData.exchange}
                </span>
              </div>
              <div className='flex justify-end gap-1'>
                {pairData.enabled ? (
                  <IconButton
                    size='md'
                    color='warning'
                    onClick={() => onTogglePair(pairData.exchange, pairData.pair, false, url)}
                  >
                    <PauseIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    size='md'
                    color='success'
                    onClick={() => onTogglePair(pairData.exchange, pairData.pair, true, url)}
                  >
                    <PlayIcon />
                  </IconButton>
                )}
                <IconButton color='warning' onClick={() => setSelectedPairData(pairData)}>
                  <PencilSquareIcon />
                </IconButton>
                <IconButton
                color='success'
                onClick={() => onViewChart(pairData.exchange, pairData.pair, url)}
              >
                <ChartBarIcon />
              </IconButton>
              <IconButton
                  color='error'
                  onClick={() => onDeletePair(pairData.exchange, pairData.pair, url)}
                >
                  <TrashIcon />
                </IconButton>
              </div>
              <span className='text-subtitle'>Today PnL</span>
              <span className='text-right'>{pairData.todayPnl}</span>
              <span className='text-subtitle'>Year Pnl</span>
              <span className='text-right'>{pairData.yearPnl}</span>
              <span className='text-subtitle'>uPnl</span>
              <span className='text-right'>{pairData.unrealPnl}</span>
              <span className='text-subtitle'>ROI%</span>
              <span className='text-right'>{pairData.gunbotROI}</span>
              <span className='text-subtitle'>Risk/Reward</span>
              <span className='text-right'>{pairData.riskReward}</span>
              <span className='text-subtitle'>Max DrawDown</span>
              <span className='text-right'>{pairData.MDD}</span>
              <span className='text-subtitle'>Daily pnl/1k</span>
              <span className='text-right'>{pairData.dailypnl}</span>
              <span className='text-subtitle'>Base Balance</span>
              <span className='text-right'>{pairData.baseBalance}</span>
              <span className='text-subtitle'>Quote Balance</span>
              <span className='text-right'>{pairData.quoteBalance}</span>
              <span className='text-subtitle'>Bag Value</span>
              <span className='text-right'>{pairData.bagvalue}</span>
            </div>
          ))}
        </div>
      )}
      <BaseDialog
        className='h-full w-full rounded-lg'
        open={!!chartUrl}
        onClose={() => setChartUrl('')}
      >
        <iframe src={chartUrl} className='h-full w-full' />
      </BaseDialog>
    </div>
  )
}

export default SettingDetail
