import React from 'react'
import clsx from 'clsx'

import { BaseInputProps } from 'src/types'

type Props = {
  checked?: boolean
} & BaseInputProps

function BaseSwitch({ checked, id, className, onChange, ...others }: Props) {
  return (
    <div
      className={clsx('relative h-[28px] w-[54px] rounded-full border', className, {
        'border-primary': checked,
        'border-subtitle2': !checked,
      })}
    >
      <div
        className={clsx(
          'absolute bottom-[2px] top-[2px] aspect-square h-[22px] rounded-full transition-all duration-200',
          {
            'left-[28px] bg-primary': checked,
            'left-[2px] bg-subtitle2': !checked,
          },
        )}
      />
      <input
        type='checkbox'
        id={id}
        checked={checked}
        className='absolute inset-0 z-[1] cursor-pointer opacity-0'
        onChange={onChange}
        {...others}
      />
    </div>
  )
}

export default BaseSwitch
