import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'

import { RejectedValue } from 'src/libs/error.handler'
import eventEmitter from 'src/libs/event.emitter'

import GradientButton from 'src/components/buttons/GradientButton'
import BaseDialog from 'src/components/dialogs/BaseDialog'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'
import LoadingScreen from 'src/components/spinners/LoadingScreen'

import { AppDispatch } from 'src/store'
import {
  logoutAction,
  selectIsRegistred,
  selectToken,
  selectUserdata,
  signinExpiredSessionAction,
  tokenSigninAction,
} from 'src/store/auth'

type Props = {
  children: React.ReactNode
}

function AuthProvider({ children }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams] = useSearchParams()
  const urlToken = searchParams.get('token')
  const [checking, setChecking] = useState(true)
  const token = useSelector(selectToken)
  const isRegistered = useSelector(selectIsRegistred)
  const userdata = useSelector(selectUserdata)
  const { id, email, createdAt } = userdata || {}

  const [{ message: errMsg }, setError] = useState<RejectedValue>({})
  const [open, setOpen] = useState(false)
  const [password, setPassword] = useState('')

  const handleClose = useCallback(() => {
    dispatch(logoutAction())
  }, [])

  const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value)
  }, [])

  const handleLogin = useCallback(() => {
    if (id && email && password && createdAt) {
      dispatch(signinExpiredSessionAction({ id, email, password, createdAt }))
    }
  }, [id, email, password, createdAt])

  useEffect(() => {
    if (urlToken) {
      dispatch(tokenSigninAction({ token: urlToken })).finally(() => setChecking(false))
    } else {
      dispatch(tokenSigninAction()).finally(() => setChecking(false))
    }
  }, [urlToken])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (token && email) {
      const now = Date.now()
      const { exp } = jwtDecode(token)
      if (exp) {
        timer = setTimeout(() => setOpen(true), exp * 1000 - now)
        return () => {
          clearTimeout(timer)
        }
      }
    }
    setOpen(false)
  }, [token, email])

  useEffect(() => {
    const onSuccess = () => {
      setPassword('')
      setOpen(false)
      setError({})
    }
    const onError = (error: RejectedValue) => {
      setError(error)
    }
    eventEmitter.on(signinExpiredSessionAction.fulfilled.type, onSuccess)
    eventEmitter.on(signinExpiredSessionAction.rejected.type, onError)
    return () => {
      eventEmitter.off(signinExpiredSessionAction.fulfilled.type, onSuccess)
      eventEmitter.off(signinExpiredSessionAction.rejected.type, onError)
    }
  }, [])

  if (checking || isRegistered === undefined) {
    return <LoadingScreen />
  }

  return (
    <>
      {email && (
        <BaseDialog
          open={open}
          onClose={handleClose}
          className='z-[9999] w-full max-w-md rounded-lg'
        >
          <h6>Session Expired</h6>
          <div className='text-subtitle'>Please input your password again.</div>
          <div className='mt-4 flex flex-col gap-4'>
            <div>
              <label className='text-sm text-subtitle'>Password *</label>
              <BaseRoundedInput
                className='rounded-md'
                inputClassName='text-sm py-2'
                name='chatId'
                autoComplete='off'
                type='password'
                value={password}
                onKeyEnter={handleLogin}
                onChange={onChangePassword}
              />
              {errMsg && <span className='text-error'>Password is wrong</span>}
            </div>
            <GradientButton
              className='mt-2 rounded-md'
              disabled={!email || !password}
              onClick={handleLogin}
            >
              Save
            </GradientButton>
          </div>
        </BaseDialog>
      )}
      {children}
    </>
  )
}

export default AuthProvider
