import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import errorHandler from 'src/libs/error.handler'

import {
  createOrUpdateSmtp,
  createOrUpdateTelegram,
  loadSmtp,
  loadTelegram,
  SmtpRes,
  TelegramRes,
  toggleSmtp,
  toggleTelegram,
} from './settings.api'
import { RootState } from '..'

type SettingsState = {
  smtp?: SmtpRes
  telegram?: TelegramRes
}

const initialState: SettingsState = {}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createOrUpdateSmtpAction.fulfilled, (state, action) => {
        state.smtp = action.payload
      })
      .addCase(loadSmtpAction.fulfilled, (state, action) => {
        if (action.payload) {
          state.smtp = action.payload
        }
      })
      .addCase(createOrUpdateTelegramAction.fulfilled, (state, action) => {
        state.telegram = action.payload
      })
      .addCase(loadTelegramAction.fulfilled, (state, action) => {
        if (action.payload) {
          state.telegram = action.payload
        }
      })
  },
})

export const createOrUpdateSmtpAction = createAsyncThunk(
  'settings/createOrUpdateSmtp',
  errorHandler(createOrUpdateSmtp),
)
export const toggleSmtpAction = createAsyncThunk('settings/toggleSmtp', errorHandler(toggleSmtp))
export const loadSmtpAction = createAsyncThunk('settings/loadSmtp', errorHandler(loadSmtp))

export const createOrUpdateTelegramAction = createAsyncThunk(
  'settings/createOrUpdateTelegram',
  errorHandler(createOrUpdateTelegram),
)
export const toggleTelegramAction = createAsyncThunk(
  'settings/toggleTelegram',
  errorHandler(toggleTelegram),
)
export const loadTelegramAction = createAsyncThunk(
  'settings/loadTelegram',
  errorHandler(loadTelegram),
)

export const selectSmtp = (state: RootState) => state.settings.smtp
export const selectTelegram = (state: RootState) => state.settings.telegram

const settingsReducer = settingsSlice.reducer
export default settingsReducer
