import React from 'react'
import clsx from 'clsx'

import { BaseButtonProps, ElementThemeProps } from 'src/types'

type Props = { whiteMode?: boolean } & ElementThemeProps & BaseButtonProps

function IconButton({
  size = 'md',
  color = 'default',
  className,
  whiteMode,
  children,
  ...others
}: Props) {
  return (
    <button
      className={clsx(
        'transition-colors duration-200 focus:outline-none disabled:text-white/30',
        className,
        {
          'size-3': size === 'xs',
          'size-4': size === 'sm',
          'size-5': size === 'md',
          'size-6': size === 'lg',
          'size-7': size === 'xl',
          'size-8': size === '2xl',

          'text-gray-800': color === 'black' && !whiteMode,
          'text-gray-500': color === 'gray' && !whiteMode,
          'text-teal-500': color === 'success' && !whiteMode,
          'text-blue-600': color === 'info' && !whiteMode,
          'text-red-500': color === 'error' && !whiteMode,
          'text-yellow-500': color === 'warning' && !whiteMode,
          'text-white': color === 'default' || whiteMode,
          'text-gunthy': color === 'primary' && !whiteMode,

          'hover:text-gray-500': color === 'black',
          'hover:text-gray-800': color === 'gray',
          'hover:text-teal-400': color === 'success',
          'hover:text-blue-500': color === 'info',
          'hover:text-red-400': color === 'error',
          'hover:text-yellow-400': color === 'warning',
          'hover:text-white/70': color === 'default',
          'hover:text-opacity-80': color === 'primary',
        },
      )}
      {...others}
    >
      {children}
    </button>
  )
}

export default IconButton
