import React, { ReactNode } from 'react'
import clsx from 'clsx'

import BaseButton from './BaseButton'
import GradientWrapper from '../wrappers/GradientWrraper'

import { BaseButtonProps, ElementThemeProps } from 'src/types'

type Props = {
  border?: number
  icon?: ReactNode
} & Omit<ElementThemeProps, 'variant'> &
  BaseButtonProps

function GradientButton({
  border = 2,
  className,
  children,
  type = 'button',
  icon,
  disabled,
  ...others
}: Props) {
  return (
    <GradientWrapper
      border={border}
      gradientOpacity={disabled ? 0.2 : 1}
      className={clsx('inline-block', className, { 'hover:opacity-80': !disabled })}
    >
      <BaseButton className='w-full' disabled={disabled} type={type} {...others}>
        {icon && icon}
        <div className={clsx('gradient-text inline-flex items-center')}>{children}</div>
      </BaseButton>
    </GradientWrapper>
  )
}

export default GradientButton
