import axios from 'src/libs/axios'

export type SmtpReq = {
  id?: number
  host: string
  port: number
  username: string
  password: string
  secure: boolean
}

export type SmtpRes = {
  id: number
} & Omit<SmtpReq, 'id'>

export const createOrUpdateSmtp = async (payload: SmtpReq) => {
  return axios.post<SmtpRes>('/api/smtp', payload).then((res) => res.data)
}

export const toggleSmtp = async (payload: boolean) => {
  return axios.post<boolean>('/api/smtp/toggle', { enabled: payload }).then((res) => res.data)
}

export const loadSmtp = async (payload?: undefined) => {
  return axios.get<SmtpRes | null>('/api/smtp', payload).then((res) => res.data)
}

export type TelegramReq = {
  id?: number
  chatId: string | number
  botToken: string
}

export type TelegramRes = {
  id: number
} & Omit<TelegramReq, 'id'>

export const createOrUpdateTelegram = async (payload: TelegramReq) => {
  return axios.post<TelegramRes>('/api/telegram', payload).then((res) => res.data)
}

export const toggleTelegram = async (payload: boolean) => {
  return axios.post<boolean>('/api/telegram/toggle', { enabled: payload }).then((res) => res.data)
}

export const loadTelegram = async (payload?: undefined) => {
  return axios.get<TelegramRes | null>('/api/telegram', payload).then((res) => res.data)
}
