import React, { CSSProperties } from 'react'
import clsx from 'clsx'

import { BaseDivProps } from 'src/types'

type Props = {
  border?: number
  gradientOpacity?: number
} & BaseDivProps

function GradientWrapper({ border = 1, className, children, gradientOpacity = 1 }: Props) {
  return (
    <div className={clsx('relative', className)}>
      {children}
      {border !== 0 && (
        <div
          className='gradient-border pointer-events-none absolute inset-0 z-[1]'
          style={{ '--b': border + 'px', opacity: gradientOpacity } as CSSProperties}
        />
      )}
    </div>
  )
}

export default GradientWrapper
