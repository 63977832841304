import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EnvelopeOpenIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import GradientButton from 'src/components/buttons/GradientButton'

import { AppDispatch } from 'src/store'
import { selectIsSmtp } from 'src/store/auth'
import { loadSmtpAction, selectSmtp, toggleSmtpAction } from 'src/store/setttings'

import OnOrOff from './OnOrOff'
import SmtpDialog from './SmtpDialog'

import { BaseDivProps } from 'src/types'

function SmtpNotification({ className }: BaseDivProps) {
  const dispatch = useDispatch<AppDispatch>()

  const enabledSmtp = useSelector(selectIsSmtp)
  const smtp = useSelector(selectSmtp)
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const handleDisable = useCallback(() => dispatch(toggleSmtpAction(false)), [])
  const handleEnable = useCallback(() => dispatch(toggleSmtpAction(true)), [])

  useEffect(() => {
    dispatch(loadSmtpAction())
  }, [])

  return (
    <div
      className={clsx(
        'flex flex-col gap-4 md:flex-row md:items-center md:justify-between',
        className,
      )}
    >
      <div className='flex flex-col gap-1'>
        <div className='flex gap-2'>
          <EnvelopeOpenIcon className='min-w-[18px]' width={18} />
          <div className='font-bold'>Email Notification (SMTP)</div>
        </div>
        <div className='text-subtitle md:pl-6'>
          This will send you notification via email. Please set smtp credentials.
        </div>
      </div>
      <div className='flex flex-row-reverse items-center justify-end gap-4 md:flex-row md:gap-8'>
        <OnOrOff enabled={!!enabledSmtp} className='w-14' />
        {enabledSmtp ? (
          <div className='flex gap-3'>
            <GradientButton size='sm' className='rounded-md' onClick={handleOpen}>
              Edit
            </GradientButton>
            <GradientButton size='sm' className='rounded-md' onClick={handleDisable}>
              Disable
            </GradientButton>
          </div>
        ) : (
          <GradientButton
            size='sm'
            className='rounded-md'
            onClick={smtp ? handleEnable : handleOpen}
          >
            Enable
          </GradientButton>
        )}
      </div>
      <SmtpDialog open={open} onClose={handleClose} />
    </div>
  )
}

export default SmtpNotification
