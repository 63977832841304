import { useRef, useState } from 'react'
import BaseDropDown from 'src/pages/Home/components/BaseDropDown'

//import { MdHelp } from 'react-icons/md'
const MdHelpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
    <path fill="white" d="M480 936q-150 0-255-105T120 576q0-150 105-255t255-105q150 0 255 105t105 255q0 150-105 255T480 936Zm-30-260h60V616h-60v60Zm30-266q-45 0-75 30t-30 75h60q0-25 15-40t30-15q18 0 30.5 12.5T525 526q0 12-6.5 23T493 573q-19 19-30 36t-11 49h60q0-20 9-35t31-31q30-30 36.5-50t6.5-37q0-45-30-75t-75-30ZM480 856q125 0 212.5-87.5T780 556q0-125-87.5-212.5T480 256q-125 0-212.5 87.5T180 556q0 125 87.5 212.5T480 856Z"/>
  </svg>
);

interface BaseTooltipProps {
  tooltip: string
}

function BaseTooltip(props: BaseTooltipProps) {
  const { tooltip } = props
  const ref = useRef<HTMLSpanElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleMouseEnter = () => {
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    setIsOpen(false)
  }
  return (
    <span ref={ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MdHelpIcon />
      <BaseDropDown
        className='max-w-96 rounded-md bg-stone-700 text-sm text-[#eaeaea]'
        float='middle'
        vFloat='up'
        anchor={ref.current}
        open={isOpen}
        onClose={handleMouseLeave}
      >
        <div className='whitespace-pre-wrap p-2'>{tooltip}</div>
      </BaseDropDown>
    </span>
  )
}

export default BaseTooltip
