import { GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'

import axios from 'src/libs/axios'
import { AsyncThunkConfig } from 'src/libs/error.handler'
import { urlFromBot } from 'src/libs/helper'

import { selectGunbotConfig } from '.'
import { RootState } from '..'

import { Response } from 'src/types'

export type BotReq = {
  name: string
  protocol: 'https' | 'http'
  domain: string
  port: number
  gunthyWallet: string
}

export type BotRes = {
  id: number
  pairCount?: number
  start?: boolean
  alive?: boolean
  isRegistered?: boolean
  isTwoFA?: boolean
  token?: string
  loggedIn?: boolean
  version?: string
} & BotReq

export const addBot = async (payload: BotReq) => {
  return axios.post<Response<BotRes>>('/api/bot', payload).then((res) => res.data)
}

export const loadBots = async (_: undefined) => {
  return axios.get<BotRes[]>('/api/bot/all', _).then((res) => res.data)
}

export const updateBot = async ({ name, id }: { name: string; id: number }) => {
  return axios.put<Response<BotRes>>('/api/bot/' + id, { name }).then((res) => res.data)
}

export const deleteBot = async (id: number) => {
  return axios.delete<Response<{ id: number }>>('/api/bot/' + id).then((res) => res.data)
}

export const getLatestVersion = async (_: undefined) => {
  return axios.get<string>('/api/bot/latestVersion').then((res) => res.data)
}

export type GunbotStatus = {
  status: 'success' | 'error'
  isRegistered: boolean
  isTwoFA: boolean
  message: string
}
export const checkGunbotStatus = async (bot: BotRes) => {
  return axios
    .get<GunbotStatus>('/api/v1/auth/checkUserStatus', { baseURL: urlFromBot(bot) })
    .then((res) => res.data)
}

type GunbotLoginReq = {
  bot: BotRes
  req: { password: string; twoFA?: string }
}

export const loginGunbot = async ({ bot, req }: GunbotLoginReq) => {
  return axios
    .post<{ token: string }>('/api/v1/auth/login', req, { baseURL: urlFromBot(bot) })
    .then((res) => res.data)
}

export const logoutGunbot = async (bot: BotRes) => {
  return Promise.resolve(bot)
}

export type BotLoadDataReq = {
  processLoadedCorememData?: boolean
  processLoadedConfig?: boolean
  processLoadedConnectedExchanges?: boolean
  processLoadedIMAP?: boolean
  processLoadedWEBHOOKS?: boolean
  processLicenseData?: boolean
}

type LoadGunbotDataReq = {
  bot: BotRes
  req: BotLoadDataReq
}

export type GunbotExchanges = {
  [exchange: string]: {
    key: string
    secret: string
    passphrase?: string
    masterkey: string
    mastersecret: string
    master_passphrase?: string
    marketType: string
  }
}

export type GunbotConfig = {
  GUI: {
    start: boolean
  }
  pairs: {
    [exchange: string]: {
      [pair: string]: {
        enabled: boolean
        override: {
          [name: string]: StratSettingValue
        }
        strategy: string
      }
    }
  }
  strategies: {
    [strategy: string]: {
      BUY_METHOD: string
      SELL_METHOD: string
      [key: string]: unknown
    }
  }
  exchanges: GunbotExchanges
  webhooks: unknown
  ['imap_listener']: unknown
}

export type GunbotCoremem = {
  memory: {
    [exchangePair: string]: {
      Ask: number
      Bid: number
      baseBalance: number
      quoteBalance: number
      gunthywallet: string
      unreal_pnl: number
      gunbotROI: number
      riskReward: number
      CUE: number
      MDD: number
      dailypnl: number
      bagvalue: number
    }
  }
}

type LoadGunbotDataRes = {
  processLoadedCorememData?: GunbotCoremem
  processLoadedConfig?: GunbotConfig
  processLoadedConnectedExchanges?: GunbotExchanges
  processLoadedIMAP?: unknown
  processLoadedWEBHOOKS?: unknown
}

export const loadGunbotData = async ({ bot, req }: LoadGunbotDataReq) => {
  return axios
    .post<
      LoadGunbotDataRes & { status?: 'error' }
    >('/api/v1/loadAvailableGUIData', { keys: req }, { baseURL: urlFromBot(bot) })
    .then((res) => res.data)
}

export const startGunbot = async (bot: BotRes) => {
  return axios
    .post<
      GunbotConfig & { status?: 'error' }
    >('/api/v1/settings/startCore', {}, { baseURL: urlFromBot(bot) })
    .then((res) => res.data)
}

export const stopGunbot = async (bot: BotRes) => {
  return axios
    .post<
      GunbotConfig & { status?: 'error' }
    >('/api/v1/settings/stopCore', {}, { baseURL: urlFromBot(bot) })
    .then((res) => res.data)
}

export const saveGunbotConfig = async (url: string, thunkApi: GetThunkAPI<AsyncThunkConfig>) => {
  const state = thunkApi.getState() as RootState
  const config = selectGunbotConfig(url)(state)
  return axios
    .post<GunbotConfig>('/api/v1/settings/updateConfig', { data: config }, { baseURL: url })
    .then((res) => res.data)
}

export const upgradeGunbot = async (url: string) => {
  return axios.post<void>('/api/v1/upgrade', {}, { baseURL: url }).then((res) => res.data)
}

export const getGunbotVersion = async (url: string) => {
  return await axios.get<string>('/api/v1/version', { baseURL: url }).then((res) => res.data)
}
export type StratSettingValue = string | number | boolean

export type StratSetting = {
  defaultValue: StratSettingValue
  tooltip?: string
  inputType?: 'string' | 'number' | 'boolean'
  exchangeSpecific?: string[]
}

export type CustomStratEditor = {
  [itemName: string]: {
    description: string
    chip?: string
    isHeadline?: boolean
    parameters: {
      [settingName: string]: StratSetting
    }
    license?: string[]
    isCustom?: boolean
    category?: string
  }
}

export type CustomStratEditors = {
  [stratName: string]: CustomStratEditor
}

type CustomStratEditorsRes = {
  status: 'success' | 'error'
  templates: CustomStratEditors
}

export const getCustomStratEditors = async (url: string) => {
  return axios
    .get<CustomStratEditorsRes>('/api/v1/data/customStratEditors', { baseURL: url })
    .then((res) => res.data)
}
