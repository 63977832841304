import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { selectUserdata } from 'src/store/auth'

type Props = {
  unauthorize?: boolean
}

function AuthGuard({ unauthorize = false }: Props) {
  const { pathname } = useLocation()
  const userdata = useSelector(selectUserdata)
  if (!unauthorize) {
    if (userdata) {
      return <Outlet />
    }
    return (
      <>
        <Navigate to='/login' replace state={{ from: pathname }} />
      </>
    )
  } else {
    if (userdata) {
      return (
        <>
          <Navigate to='/' replace />
        </>
      )
    } else {
      return <Outlet />
    }
  }
}

export default AuthGuard
