import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import GradientButton from 'src/components/buttons/GradientButton'

import { AppDispatch } from 'src/store'
import { selectIsTelegramEnabled } from 'src/store/auth'
import { loadTelegramAction, selectTelegram, toggleTelegramAction } from 'src/store/setttings'

import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram-icon.svg'

import OnOrOff from './OnOrOff'
import TelegramDialog from './TelegramDialog'

import { BaseDivProps } from 'src/types'

function TelegramNotification({ className }: BaseDivProps) {
  const dispatch = useDispatch<AppDispatch>()
  const isEnabled = useSelector(selectIsTelegramEnabled)
  const telegram = useSelector(selectTelegram)

  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const handleEnable = useCallback(() => dispatch(toggleTelegramAction(true)), [])
  const handleDisable = useCallback(() => dispatch(toggleTelegramAction(false)), [])

  useEffect(() => {
    dispatch(loadTelegramAction())
  }, [])

  return (
    <div
      className={clsx(
        'flex flex-col gap-4 md:flex-row md:items-center md:justify-between',
        className,
      )}
    >
      <div className='flex flex-col gap-1'>
        <div className='flex items-start gap-2'>
          <TelegramIcon className='min-w-[18px]' width={18} />
          <div className='font-bold'>Telegram Notification</div>
        </div>
        <div className='text-subtitle md:pl-6'>
          This will send you notification using telegram bot. Please set telegram bot.
        </div>
      </div>
      <div className='flex flex-row-reverse items-center justify-end gap-4 md:flex-row md:gap-8'>
        <OnOrOff enabled={!!isEnabled} className='w-14' />
        {isEnabled ? (
          <div className='flex gap-3'>
            <GradientButton size='sm' className='rounded-md' onClick={handleOpen}>
              Edit
            </GradientButton>
            <GradientButton size='sm' className='rounded-md' onClick={handleDisable}>
              Disable
            </GradientButton>
          </div>
        ) : (
          <GradientButton
            size='sm'
            className='rounded-md'
            onClick={telegram ? handleEnable : handleOpen}
          >
            Enable
          </GradientButton>
        )}
      </div>
      <TelegramDialog open={open} onClose={handleClose} />
    </div>
  )
}

export default TelegramNotification
