import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PencilSquareIcon, PlayIcon, StopIcon, TrashIcon } from '@heroicons/react/24/solid'
import { clsx } from 'clsx'
import _ from 'lodash'

import { urlFromBot } from 'src/libs/helper'

import { useGunbotAuth } from 'src/providers/BotLoginProvider'
import BaseButton from 'src/components/buttons/BaseButton'
import IconButton from 'src/components/buttons/IconButton'
import BaseTable, { Column, DetailedPanelProps } from 'src/components/tables/BaseTable'

import { AppDispatch } from 'src/store'
import {
  saveGunbotConfigAction,
  selectAllPnlHistory,
  selectBots,
  selectIsChangedGunbotConfig,
  selectIsGunbotLoading,
} from 'src/store/bots'
import { BotRes } from 'src/store/bots/bots.api'

import SettingDetail from './SettingDetail'

type ColumnItem = {
  today?: number
  yesterday?: number
  week?: number
  year?: number
} & BotRes

type Props = {
  onEditBot: (bot: ColumnItem) => void
  onDeleteBot: (bot: ColumnItem) => void
  onStopGunbot: (bot: ColumnItem) => void
  onStartGunbot: (bot: ColumnItem) => void
}

function BotsTableComponent({ onEditBot, onDeleteBot, onStartGunbot, onStopGunbot }: Props) {
  const bots = useSelector(selectBots)
  const allPnlHistory = useSelector(selectAllPnlHistory)
  const handleGunbotAuth = useGunbotAuth()

  const DetailedPanel = useMemo(() => {
    const DetailedPanel = ({ rowData, index }: DetailedPanelProps<BotRes>) => {
      const url = urlFromBot(rowData)
      return (
        <SettingDetail
          url={url}
          rowIndex={index}
          bot={rowData}
          onStartGunbot={onStartGunbot}
          onStopGunbot={onStopGunbot}
          onEditBot={onEditBot}
          onDeleteBot={onDeleteBot}
        />
      )
    }
    return DetailedPanel
  }, [])

  const columns = useMemo(() => {
    const columns: Column<ColumnItem>[] = [
      {
        label: 'Name',
        field: 'name',
        headerClass: 'py-2 xl:py-3 pr-2 xl:pr-4',
        cellClass: 'py-2 xl:py-3 pr-2 xl:pr-4',
        render: ({ alive, name }) => {
          return (
            <div className='inline-flex items-center gap-2'>
              <span
                className={clsx('h-2 w-2 rounded-full', {
                  'bg-success': alive,
                  'bg-error': !alive,
                })}
              />
              <span>{name}</span>
            </div>
          )
        },
      },
      {
        label: 'Pairs',
        field: 'pairCount',
        headerClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center hidden sm:table-cell',
        cellClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center hidden sm:table-cell',
      },
      {
        label: 'Today PnL',
        field: 'today',
        headerClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center hidden 2md:table-cell',
        cellClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center hidden 2md:table-cell',
        render: (item) => {
          if (_.isNil(item.today)) return '-'
          const value = item.today;
          const formattedValue = Number(value?.toPrecision(8));
          return <span>{formattedValue}</span>;
        },
      },
      {
        label: 'Yesterday PnL',
        field: 'yesterday',
        headerClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center hidden lg:table-cell',
        cellClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center hidden lg:table-cell',
        render: (item) => {
          if (_.isNil(item.today)) return '-'
          const value = item.yesterday;
          const formattedValue = Number(value?.toPrecision(8));
          return <span>{formattedValue}</span>;
        },
      },
      {
        label: 'Weekly PnL',
        field: 'week',
        headerClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center hidden 2md:table-cell',
        cellClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center hidden 2md:table-cell',
        render: (item) => {
          if (_.isNil(item.week)) return '-'
          const value = item.week;
          const formattedValue = Number(value?.toPrecision(8));
          return <span>{formattedValue}</span>;
        },
      },
      {
        label: 'Yearly PnL',
        field: 'year',
        headerClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center hidden md:table-cell',
        cellClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center hidden md:table-cell',
        render: (item) => {
          if (_.isNil(item.year)) return '-'
          const value = item.year;
          const formattedValue = Number(value?.toPrecision(8));
          return <span>{formattedValue}</span>;
        },
      },
      {
        label: 'On/Off',
        headerClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center  hidden sm:table-cell',
        cellClass: 'py-2 xl:py-3 pr-2 xl:px-4 text-center  hidden sm:table-cell',
        render: (bot) => {
          const { start, loggedIn } = bot
          return start ? (
            <IconButton
              size='md'
              color='error'
              disabled={!loggedIn}
              onClick={() => onStopGunbot(bot)}
            >
              <StopIcon />
            </IconButton>
          ) : (
            <IconButton
              size='md'
              color='success'
              disabled={!loggedIn}
              onClick={() => onStartGunbot(bot)}
            >
              <PlayIcon />
            </IconButton>
          )
        },
      },
      {
        label: 'Login',
        field: 'loggedIn',
        headerClass: 'text-center py-2 xl:py-3 hidden sm:table-cell',
        cellClass: 'text-center py-2 hidden sm:table-cell',
        render: (bot) => {
          if (!bot.loggedIn) {
            return (
              <BaseButton
                className='w-fit text-nowrap rounded-md'
                color='primary'
                size='xs'
                fontSize='2xs'
                variant='outlined'
                disabled={!bot.alive}
                onClick={() => handleGunbotAuth(bot, true)}
              >
                Log in
              </BaseButton>
            )
          }
          return (
            <BaseButton
              className='w-fit text-nowrap rounded-md'
              color='error'
              size='xs'
              fontSize='2xs'
              variant='outlined'
              disabled={!bot.alive}
              onClick={() => handleGunbotAuth(bot, false)}
            >
              Log out
            </BaseButton>
          )
        },
      },
      {
        label: 'Actions',
        render: (bot) => (
          <div className='inline-flex items-center gap-2 whitespace-nowrap px-2 xl:px-3'>
            <SaveChangesBtn url={urlFromBot(bot)} />
            <IconButton color='warning' className='min-h-5 min-w-5' onClick={() => onEditBot(bot)}>
              <PencilSquareIcon />
            </IconButton>
            <IconButton color='error' onClick={() => onDeleteBot(bot)} className='min-h-5 min-w-5'>
              <TrashIcon />
            </IconButton>
          </div>
        ),
        headerClass: 'text-center py-2 xs:py-3 pr-4 hidden sm:table-cell',
        cellClass: 'w-0 text-center py-2 pr-4 hidden sm:table-cell',
      },
    ]
    return columns
  }, [])

  const tableData: ColumnItem[] = useMemo(() => {
    return bots.map((bot) => {
      const url = urlFromBot(bot)
      const pnl = allPnlHistory[url]
      if (pnl) {
        return Object.assign({}, bot, {
          today: pnl.today.pnl,
          yesterday: pnl.yesterday.pnl,
          week: pnl.weekAgo.pnl,
          year: pnl.yearAgo.pnl,
        })
      }
      return bot
    })
  }, [bots, allPnlHistory])

  return (
    <BaseTable
      columns={columns}
      data={tableData}
      className='mt-4'
      border
      DetailedPanel={DetailedPanel}
      defaultActiveDetailIndex={-1}
      fullDetailedPanel
    />
  )
}

export default BotsTableComponent

export function SaveChangesBtn({ url }: { url: string }) {
  const changed = useSelector(selectIsChangedGunbotConfig(url))
  const loading = useSelector(selectIsGunbotLoading(url))
  const dispatch = useDispatch<AppDispatch>()

  const handleSaveConfig = useCallback(() => {
    dispatch(saveGunbotConfigAction(url))
  }, [url])

  return changed ? (
    <BaseButton
      size='xs'
      fontSize='xs'
      variant='outlined'
      className={clsx('rounded-md ', {
        'animate-save-changes shadow-md shadow-yellow-300': !loading,
      })}
      disabled={loading}
      color='primary'
      onClick={handleSaveConfig}
    >
      SAVE
    </BaseButton>
  ) : (
    <></>
  )
}
