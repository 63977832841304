import fromExponential from 'from-exponential'

import { BotReq } from 'src/store/bots/bots.api'

import { TOKEN_STORAGE_KEY } from './storage'

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function urlFromBot({ protocol, domain, port }: BotReq) {
  return `${protocol}://${domain}:${port}`
}

export function wsUrlFromBot({ protocol, domain, port }: BotReq) {
  const wsProtocol = protocol === 'https' ? 'wss' : 'ws'
  return `${wsProtocol}://${domain}:${port}`
}

export function botTokenStorageKey(baseURL: string) {
  return baseURL + '_' + TOKEN_STORAGE_KEY
}

export function numFormatter(num = 0) {
  if (num !== 0 && !num) return NaN
  const absNum = Math.abs(num)
  if (absNum >= 1000000) {
    return (num / 1000000).toLocaleString('en-US', { maximumFractionDigits: 3 }) + 'M'
  } else if (absNum < 1000000 && absNum > 999) {
    return (num / 1000).toLocaleString('en-US', { maximumFractionDigits: 3 }) + 'K'
  } else if (absNum < 900 && absNum >= 1) {
    return num.toLocaleString('en-US', { maximumFractionDigits: 2 })
  } else {
    return toDecimal(num)
  }
}

export function toDecimal(num = 0, dp = 8) {
  if (num !== 0 && !num) return NaN
  return fromExponential(+num.toFixed(dp))
}
