import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import { io } from 'socket.io-client'

import eventEmitter from 'src/libs/event.emitter'
import { urlFromBot, wsUrlFromBot } from 'src/libs/helper'
import Storage, { TOKEN_STORAGE_KEY } from 'src/libs/storage'

import { AppDispatch } from 'src/store'
import {
  checkGunbotStatusAction,
  DownloadStatus,
  getGunbotVersionAction,
  loadGunbotDataAction,
  loginGunbotAction,
  setPnlHistory,
  updateBotAlive,
  updateGunbotUpgradingStatus,
} from 'src/store/bots'
import { BotLoadDataReq, BotRes } from 'src/store/bots/bots.api'

type Props = {
  bot: BotRes
}

type BotChangeEventParams = {
  corememDataChanged?: boolean
  'CONFIG-CHANGED'?: boolean
}

const defaultBotChangeParams: BotChangeEventParams = {
  corememDataChanged: true,
  'CONFIG-CHANGED': true,
}

export type PnlHistory = {
  dailyHistory: unknown[]
  pnlsPerPair: unknown[]
  detailedPerPair: unknown[]
  today: { pnl: number; perPair: { [key: string]: { pnl: number } } }
  yesterday: { pnl: number }
  weekAgo: { pnl: number }
  monthAgo: { pnl: number }
  yearAgo: { pnl: number; perPair: { [key: string]: { pnl: number } } }
  lastYear: { pnl: number }
  total: unknown
  unit: string
}

function BotSocket({ bot }: Props) {
  const socketUrl = wsUrlFromBot(bot)
  const dispatch = useDispatch<AppDispatch>()

  const loadData = useCallback(
    (changes: BotChangeEventParams = defaultBotChangeParams) => {
      const availableKeys: BotLoadDataReq = {}

      if (changes['corememDataChanged']) availableKeys.processLoadedCorememData = true
      if (changes['CONFIG-CHANGED']) {
        availableKeys.processLoadedConfig = true
        availableKeys.processLoadedCorememData = true
        availableKeys.processLoadedConnectedExchanges = true
        availableKeys.processLoadedIMAP = true
        availableKeys.processLoadedWEBHOOKS = true
      }

      dispatch(loadGunbotDataAction({ bot, req: availableKeys }))
    },
    [bot],
  )

  useEffect(() => {
    const socket = io(socketUrl, { autoConnect: true })

    const onLogin = () => {
      socket.emit('login', {
        token: Storage.get<string>(urlFromBot(bot) + '_' + TOKEN_STORAGE_KEY),
      })
    }
    const connect = () => {
      dispatch(updateBotAlive({ id: bot.id, alive: true }))
      dispatch(getGunbotVersionAction(urlFromBot(bot)))
      onLogin()
    }
    const disconnect = () => {
      dispatch(updateBotAlive({ id: bot.id, alive: false }))
    }

    const loadPnlData = () => {
      socket.emit(
        'GET_PNL_STATISTIC_DATA',
        {
          timezone: moment.tz.guess(),
          key: ['All'],
        },
        (res: PnlHistory) => {
          dispatch(setPnlHistory({ url: urlFromBot(bot), data: res }))
        },
      )
    }

    const onUpgrading = (data: DownloadStatus) => {
      dispatch(updateGunbotUpgradingStatus({ url: urlFromBot(bot), data }))
    }

    eventEmitter.on(loginGunbotAction.fulfilled.type, onLogin)
    eventEmitter.on(loginGunbotAction.fulfilled.type, loadPnlData)
    eventEmitter.on(loginGunbotAction.rejected.type, onLogin)

    socket.on('connect', connect)
    socket.on('login', loadData)
    socket.on('Changed-GUI', loadData)
    socket.on('login', loadPnlData)
    socket.on('NEW_ORDERS', loadPnlData)
    socket.on('disconnect', disconnect)
    socket.on('DOWNLOADING:NEW_VERSION', onUpgrading)

    return () => {
      eventEmitter.off(loginGunbotAction.fulfilled.type, onLogin)
      eventEmitter.off(loginGunbotAction.fulfilled.type, loadPnlData)
      eventEmitter.off(loginGunbotAction.rejected.type, onLogin)

      socket.off('connect', connect)
      socket.off('login', loadData)
      socket.off('Changed-GUI', loadData)
      socket.off('login', loadPnlData)
      socket.off('NEW_ORDERS', loadPnlData)
      socket.off('disconnect', disconnect)
      socket.off('DOWNLOADING:NEW_VERSION', onUpgrading)
      socket.close()
    }
  }, [socketUrl])

  useEffect(() => {
    dispatch(checkGunbotStatusAction(bot))
  }, [bot.token])

  useEffect(() => {
    if (bot.loggedIn) loadData()
  }, [bot.loggedIn])

  return <></>
}

export default BotSocket
