import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import eventEmitter from 'src/libs/event.emitter'

import GradientButton from 'src/components/buttons/GradientButton'
import BaseDialog, { BaseDialogProps } from 'src/components/dialogs/BaseDialog'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'
import BaseSwitch from 'src/components/inputs/BaseSwitch'

import { AppDispatch } from 'src/store'
import { createOrUpdateSmtpAction, selectSmtp } from 'src/store/setttings'
import { SmtpReq } from 'src/store/setttings/settings.api'

type Props = BaseDialogProps

function SmtpDialog({ open, onClose }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const originSmtp = useSelector(selectSmtp)

  const [smtp, setSmtp] = useState<SmtpReq>({
    host: '',
    port: 80,
    secure: true,
    username: '',
    password: '',
  })
  const onSecureChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSmtp((prev) => ({ ...prev, secure: e.target.checked }))
  }, [])

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setSmtp((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleSave = useCallback(() => {
    dispatch(createOrUpdateSmtpAction(smtp))
  }, [smtp])

  useEffect(() => {
    if (originSmtp) {
      setSmtp(originSmtp)
    }
  }, [originSmtp])

  useEffect(() => {
    const handleClose = () => onClose?.()
    eventEmitter.on(createOrUpdateSmtpAction.fulfilled.type, handleClose)

    return () => {
      eventEmitter.off(createOrUpdateSmtpAction.fulfilled.type, handleClose)
    }
  }, [onClose])

  const isChanged =
    !_.isEqual(originSmtp, smtp) && smtp.host && smtp.port && smtp.username && smtp.password

  return (
    <BaseDialog open={open} onClose={onClose} className='w-full max-w-md rounded-lg'>
      <h5>SMTP Credentials</h5>
      <div className='text-subtitle'>Input your own smtp credentials.</div>
      <div className='mt-4 flex flex-col gap-4'>
        <div>
          <label className='text-sm text-subtitle'>Host *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='py-2'
            autoComplete='off'
            name='host'
            value={smtp.host}
            onChange={onInputChange}
          />
        </div>
        <div>
          <label className='text-sm text-subtitle'>Port *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='py-2'
            autoComplete='off'
            name='port'
            type='number'
            value={smtp.port}
            onChange={onInputChange}
          />
        </div>
        <div>
          <label className='text-sm text-subtitle'>Username *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='py-2'
            autoComplete='off'
            name='username'
            value={smtp.username}
            onChange={onInputChange}
          />
        </div>
        <div>
          <label className='text-sm text-subtitle'>Password *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='py-2'
            autoComplete='false'
            name='password'
            type='password'
            value={smtp.password}
            onChange={onInputChange}
          />
        </div>
        <div className='flex items-center gap-2'>
          <BaseSwitch checked={smtp.secure} id='smtp-secure' onChange={onSecureChange} />
          <label className='text-sm text-subtitle' htmlFor='smtp-secure'>
            Secure
          </label>
        </div>
        <GradientButton className='mt-2 rounded-md' disabled={!isChanged} onClick={handleSave}>
          Save
        </GradientButton>
      </div>
    </BaseDialog>
  )
}

export default SmtpDialog
