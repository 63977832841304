import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import './App.css'

import UpgradePage from 'src/pages/Upgrade'

import AuthGuard from './components/AuthGuard'
import FullScreenLayout from './layouts/FullScreen'
import MainLayout from './layouts/Main'
import ForgotPasswordPage from './pages/ForgotPassword'
import HomePage from './pages/Home'
import LoginPage from './pages/Login'
import SettingsPage from './pages/Settings'
import SignupPage from './pages/Signup'

function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Routes>
      <Route element={<AuthGuard />}>
        <Route path='/' element={<MainLayout />}>
          <Route path='/' element={<HomePage />} />
          <Route path='/settings' element={<SettingsPage />} />
          <Route path='/upgrade' element={<UpgradePage />} />
        </Route>
      </Route>
      <Route path='/' element={<FullScreenLayout />}>
        <Route element={<AuthGuard unauthorize />}>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/signup' element={<SignupPage />} />
          <Route path='/forgot-password' element={<ForgotPasswordPage />} />
        </Route>
      </Route>
      <Route path='*' element={<div>404 Page</div>} />
    </Routes>
  )
}

export default App
