
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'

import { BaseDivProps } from 'src/types'

type Size = number | string

type DropDownRect = {
  left?: Size
  top?: Size
  bottom?: Size
  right?: Size
  minWidth?: Size
}

interface BaseDropDownProps extends BaseDivProps {
  float?: 'left' | 'right' | 'middle'
  vFloat?: 'up' | 'down'
  open: boolean
  onClose: () => void
  anchor?: HTMLElement | null
  maxHeight?: number
}

const BaseDropDown = (props: BaseDropDownProps) => {
  const {
    maxHeight = 300,
    open,
    children,
    anchor: anchor,
    float = 'left',
    vFloat = 'down',
    className,
    onClose,
    ...rest
  } = props
  let realFloat = float
  const ref = useRef<HTMLDivElement>(null)

  const [rect, setRect] = useState<DropDownRect>({ left: -500, top: -500 })

  useEffect(() => {
    const clickAnyWhere = (e: Event) => {
      const target = e.target as unknown as HTMLElement
      let loopTarget: HTMLElement | null = target

      let flag = true
      while (loopTarget) {
        if (loopTarget === anchor) {
          flag = false
          break
        }
        if (loopTarget === ref.current) {
          flag = false
          break
        }
        loopTarget = loopTarget.parentElement
      }

      if (flag) {
        onClose()
      }
    }
    window.addEventListener('click', clickAnyWhere)

    return () => {
      window.removeEventListener('click', clickAnyWhere)
    }
  }, [onClose, anchor])

  useEffect(() => {
    const updateRect = () => {
      if (anchor && ref.current) {
        const { height: windowHeight, width: windowWidth } = document.body.getBoundingClientRect()
        const { height: panelHeight, width: panelWidth } = ref.current.getBoundingClientRect()
        const { left, top, bottom, width } = anchor.getBoundingClientRect()

        const inputLeft = left + (window.scrollX || document.documentElement.scrollLeft)
        const inputTop = top + (window.scrollY || document.documentElement.scrollTop)
        const inputBottom = bottom + (window.scrollY || document.documentElement.scrollTop)

        if (window.innerWidth - left < panelWidth) {
          realFloat = 'right'
        }
        if (left + width < panelWidth) {
          realFloat = 'left'
        }

        let mLeft: Size = inputLeft,
          mTop: Size = 0,
          mBottom: Size = 0,
          mRight: Size = 0

        if (realFloat === 'left') {
          mLeft = inputLeft
          mRight = 'auto'
        } else if (realFloat === 'right') {
          mLeft = 'auto'
          mRight = windowWidth - inputLeft - width
        } else if (realFloat === 'middle') {
          // Center the dropdown
          mLeft = inputLeft + width / 2 - panelWidth / 2
          mRight = 'auto'
          // Ensure the dropdown doesn't go off-screen
          if (mLeft < 0) mLeft = 0
          if (mLeft + panelWidth > windowWidth) mLeft = windowWidth - panelWidth
        }

        if (vFloat === 'up') {
          mTop = 'auto'
          mBottom = windowHeight - inputTop
        } else {
          mTop = inputBottom
          mBottom = 'auto'
        }

        if (top < Math.min(maxHeight, panelHeight)) {
          mTop = inputBottom
          mBottom = 'auto'
        }

        if (vFloat == 'down' && window.innerHeight - bottom < Math.min(maxHeight, panelHeight)) {
          mTop = 'auto'
          mBottom = windowHeight - inputTop
        } else if (vFloat == 'up' && top < Math.min(maxHeight, panelHeight)) {
          mTop = inputBottom
          mBottom = 'auto'
        }

        setRect({
          left: mLeft,
          top: mTop,
          right: mRight,
          bottom: mBottom,
          minWidth: width,
        })
      }
    }
    updateRect()

    window.addEventListener('resize', updateRect)
    window.addEventListener('scroll', updateRect)

    return () => {
      window.removeEventListener('resize', updateRect)
      window.removeEventListener('scroll', updateRect)
    }
  }, [open, children, anchor, maxHeight])

  return (
    <>
      {open &&
        createPortal(
          <div
            ref={ref}
            style={{ maxHeight, ...rect }}
            className={clsx('absolute z-30 overflow-auto shadow-lg', className)}
            {...rest}
          >
            {children}
          </div>,
          document.body,
        )}
    </>
  )
}

export default BaseDropDown
