import React from 'react'
import { Outlet } from 'react-router'

import BotLoginProvider from 'src/providers/BotLoginProvider'
import BotSocketProvider from 'src/providers/BotSocketProvider'
import SocketProvider from 'src/providers/SocketProvider'

import Footer from './Footer'
import Header from './Header'

function MainLayout() {
  return (
    <div className='flex min-h-screen flex-col'>
      <Header className='flex-none' />
      <div className='flex flex-auto'>
        <div className='mx-auto w-full px-2 sm:container xs:px-4 2xl:px-6 3xl:px-8'>
          <BotLoginProvider>
            <BotSocketProvider>
              <SocketProvider>
                <Outlet />
              </SocketProvider>
            </BotSocketProvider>
          </BotLoginProvider>
        </div>
      </div>
      <Footer className='flex-none' />
    </div>
  )
}

export default MainLayout
