import axios from 'src/libs/axios'

import { Response } from 'src/types'

type SignupRes = {
  new_user_id: number
}

export type SignupReq = {
  email: string
  password: string
  repeatPassword: string
}

export const signup = async (payload: SignupReq) => {
  return axios.post<Response<SignupRes>>('/api/signup', payload).then((res) => res.data)
}

export type ChangePassword = {
  oldPassword: string
  newPassword: string
  repeatPassword: string
}

export const changePassword = async (payload: ChangePassword) => {
  return axios.post<void>('/api/user/changePassword', payload).then((res) => res.data)
}

export type LoginReq = {
  email: string
  password: string
  twoFACode?: string
}

export type UserData = {
  id: number
  email: string
  smtp?: boolean
  telegram?: boolean
  twoFA?: string
  createdAt: string
}

type LoginRes = {
  token: string
  userdata: UserData
  requireTwoFA: boolean
}

export const login = async (payload: LoginReq) => {
  return axios.post<Response<LoginRes>>('/api/login', payload).then((res) => res.data)
}

export const logout = async (payload: undefined) => {
  return axios.post<Response<void>>('/api/logout', payload).then((res) => res.data)
}

type SigninExpiredSessionReq = {
  id: number
  email: string
  password: string
  createdAt: string
}
export const signinExpiredSession = async (payload: SigninExpiredSessionReq) => {
  return axios.post<LoginRes>('/api/user/signinExpiredSession', payload).then((res) => res.data)
}

type TokenSigninRes = {
  userdata: UserData
  requireTwoFA: boolean
  token?: string
}
export const tokenSignin = async (payload?: { token: string }) => {
  return axios.post<TokenSigninRes>('/api/token-signin', payload).then((res) => res.data)
}

export type TwoFARes = {
  secret: string
  QRCodeSrc: string
}

export const getTwoFASecret = async (payload: undefined) => {
  return axios.get<TwoFARes>('/api/user/getTwoFASecret', payload).then((res) => res.data)
}

export type TwoFAReq = {
  secret: string
  code: string
  password: string
}
export const enableTwoFA = async (payload: TwoFAReq) => {
  return axios.post<void>('/api/user/verifyTwoFA', payload).then((res) => res.data)
}

export const disableTwoFA = async (payload: undefined) => {
  return axios.post<void>('/api/user/disableTwoFA', payload).then((res) => res.data)
}
