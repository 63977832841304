import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import { RejectedValue } from 'src/libs/error.handler'
import eventEmitter from 'src/libs/event.emitter'

import GradientButton from 'src/components/buttons/GradientButton'
import BaseDialog, { BaseDialogProps } from 'src/components/dialogs/BaseDialog'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'

import { AppDispatch } from 'src/store'
import { changePasswordAction, logoutAction } from 'src/store/auth'
import { ChangePassword } from 'src/store/auth/auth.api'

type Props = BaseDialogProps

function ChangePasswordDialog({ className, onClose, ...others }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const [{ errors, message: errMsg }, setError] = useState<RejectedValue>({})

  const [data, setData] = useState<ChangePassword>({
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
  })

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setData((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleUpdate = useCallback(() => dispatch(changePasswordAction(data)), [data])
  const handleClose = useCallback(() => {
    setData({
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
    })
    onClose?.()
  }, [onClose])

  const isChanged = data.oldPassword && data.newPassword && data.repeatPassword

  useEffect(() => {
    const onSuccess = () => {
      dispatch(logoutAction())
      handleClose()
    }
    const onFailed = (error: RejectedValue) => setError(error)
    eventEmitter.on(changePasswordAction.fulfilled.type, onSuccess)
    eventEmitter.on(changePasswordAction.rejected.type, onFailed)

    return () => {
      eventEmitter.off(changePasswordAction.fulfilled.type, onSuccess)
      eventEmitter.off(changePasswordAction.rejected.type, onFailed)
    }
  }, [onClose])

  return (
    <BaseDialog
      className={clsx('w-full max-w-md rounded-lg', className)}
      onClose={handleClose}
      {...others}
    >
      <h5>Change Password</h5>
      <div className='text-subtitle'>Please change your password.</div>
      <div className='mt-4 flex flex-col gap-4'>
        <div>
          <label className='text-sm text-subtitle'>Current Password *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='text-sm py-2'
            name='oldPassword'
            autoComplete='off'
            type='password'
            value={data.oldPassword}
            onChange={handleInputChange}
          />
          {errMsg && <span className='text-error'>{errMsg}</span>}
          {errors?.oldPassword && (
            <span className='text-error'>{errors.oldPassword[0].message}</span>
          )}
        </div>
        <div>
          <label className='text-sm text-subtitle'>New Password *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='text-sm py-2'
            name='newPassword'
            type='password'
            autoComplete='off'
            value={data.newPassword}
            onChange={handleInputChange}
          />
          {errors?.newPassword && (
            <span className='text-error'>{errors.newPassword[0].message}</span>
          )}
        </div>
        <div>
          <label className='text-sm text-subtitle'>Repeat Password *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='text-sm py-2'
            name='repeatPassword'
            type='password'
            autoComplete='off'
            value={data.repeatPassword}
            onChange={handleInputChange}
          />
          {errors?.repeatPassword && (
            <span className='text-error'>{errors.repeatPassword[0].message}</span>
          )}
        </div>
        <GradientButton className='mt-2 rounded-md' disabled={!isChanged} onClick={handleUpdate}>
          Change
        </GradientButton>
      </div>
    </BaseDialog>
  )
}

export default ChangePasswordDialog
