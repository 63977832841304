import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

import { RejectedValue } from 'src/libs/error.handler'
import eventEmitter from 'src/libs/event.emitter'
import { urlFromBot } from 'src/libs/helper'

import GradientButton from 'src/components/buttons/GradientButton'
import BaseDialog from 'src/components/dialogs/BaseDialog'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'

import { AppDispatch } from 'src/store'
import { updateBotAction } from 'src/store/bots'
import { BotRes } from 'src/store/bots/bots.api'

type Props = {
  open: boolean
  bot: BotRes
  onClose: () => void
}

function EditBotDialog({ bot, open, onClose }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const [botName, setBotName] = useState<string>(bot.name)
  const [{ errors, message: errMsg }, setError] = useState<RejectedValue>({})

  const handleFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select()
  }, [])

  const onKeyEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      dispatch(updateBotAction({ name: e.currentTarget.value, id: bot.id }))
    },
    [bot.id],
  )

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setBotName(e.currentTarget.value)
  }, [])

  const handleChange = useCallback(() => {
    dispatch(updateBotAction({ name: botName, id: bot.id }))
  }, [bot.id, botName])

  useEffect(() => {
    const onSuccess = () => onClose()
    const onFailed = (error: RejectedValue) => setError(error)

    eventEmitter.on(updateBotAction.fulfilled.type, onSuccess)
    eventEmitter.on(updateBotAction.rejected.type, onFailed)

    return () => {
      eventEmitter.off(updateBotAction.fulfilled.type, onSuccess)
      eventEmitter.off(updateBotAction.rejected.type, onFailed)
    }
  }, [])

  return (
    <BaseDialog open={open} onClose={onClose} className='w-full max-w-md rounded-lg'>
      <div className='flex flex-col gap-4'>
        <div>
          <h6 className='mb-2 text-2xl'>
            Edit <span className='text-primary'>{bot.name}</span> Bot
          </h6>
          <div className='text-subtitle'>{urlFromBot(bot)}</div>
        </div>

        <div className='flex flex-col gap-2'>
          <label className='text-subtitle' htmlFor='bot-name'>
            Name *
          </label>
          <BaseRoundedInput
            id='bot-name'
            className='rounded-md'
            inputClassName='h-9 text-sm'
            autoFocus={open}
            onFocus={handleFocus}
            onChange={onChange}
            value={botName}
            onKeyEnter={onKeyEnter}
          />
          {errMsg && <span className='text-error'>{errMsg}</span>}
          {errors?.name && <span className='text-error'>{errors.name[0].message}</span>}
        </div>

        <GradientButton className='mt-4 rounded-full' fontSize='md' onClick={handleChange}>
          Change
        </GradientButton>
      </div>
    </BaseDialog>
  )
}

export default EditBotDialog
