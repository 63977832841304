import React, { FocusEvent, forwardRef, KeyboardEvent, useCallback, useState } from 'react'
import clsx from 'clsx'

import GradientWrapper from '../wrappers/GradientWrraper'

import { BaseInputProps } from 'src/types'

type Props = {
  inputClassName?: string
  border?: number
  gradientOpacity?: number
  onKeyEnter?: (e: KeyboardEvent<HTMLInputElement>) => void
} & BaseInputProps

const BaseRoundedInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      border = 1,
      gradientOpacity = 0.6,
      inputClassName,
      onKeyEnter,
      onFocus,
      onBlur,
      disabled,
      ...rest
    },
    ref,
  ) => {
    const [focus, setFocus] = useState(false)
    const onKeyUp = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          onKeyEnter?.(e)
        }
      },
      [onKeyEnter],
    )
    const handleFocus = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        setFocus(true)
        e.currentTarget.select()
        onFocus?.(e)
      },
      [onFocus],
    )
    const handleBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        setFocus(false)
        onBlur?.(e)
      },
      [onBlur],
    )
    return (
      <GradientWrapper
        border={border}
        gradientOpacity={disabled ? 0.3 : focus ? 1 : gradientOpacity}
        className={className}
      >
        <input
          ref={ref}
          className={clsx(
            'w-full border-0 bg-transparent px-2 py-2 placeholder-gray-500 outline-0',
            inputClassName,
          )}
          onKeyUp={onKeyUp}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          {...rest}
        />
      </GradientWrapper>
    )
  },
)

BaseRoundedInput.displayName = 'BaseRoundedInput'

export default BaseRoundedInput
