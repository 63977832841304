import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import { RejectedValue } from 'src/libs/error.handler'
import eventEmitter from 'src/libs/event.emitter'

import GradientButton from 'src/components/buttons/GradientButton'
import BaseDialog, { BaseDialogProps } from 'src/components/dialogs/BaseDialog'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'

import { AppDispatch } from 'src/store'
import { enableTwoFAAction, getTwoFASecretAction } from 'src/store/auth'
import { TwoFARes } from 'src/store/auth/auth.api'

function TwoFADialog({ className, open, onClose, ...others }: BaseDialogProps) {
  const dispatch = useDispatch<AppDispatch>()

  const [{ message: errMsg }, setError] = useState<RejectedValue>({})
  const [data, setData] = useState({
    twoFA: '',
    password: '',
  })
  const [{ secret, QRCodeSrc }, setTwoFAData] = useState({ secret: '', QRCodeSrc: '' })

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setData((prev) => ({ ...prev, [name]: value }))
  }, [])

  useEffect(() => {
    // if (open) {
    dispatch(getTwoFASecretAction())

    const onSuccess = (payload: TwoFARes) => {
      setTwoFAData(payload)
    }
    const onFailed = (error: RejectedValue) => setError(error)
    eventEmitter.on(getTwoFASecretAction.fulfilled.type, onSuccess)
    eventEmitter.on(enableTwoFAAction.rejected.type, onFailed)

    return () => {
      eventEmitter.off(getTwoFASecretAction.fulfilled.type, onSuccess)
      eventEmitter.off(enableTwoFAAction.rejected.type, onFailed)
    }
    // }
  }, [])

  const handleApply = useCallback(() => {
    dispatch(enableTwoFAAction({ code: data.twoFA, password: data.password, secret }))
  }, [data, secret])

  const canApply = data.twoFA && data.password

  return (
    <BaseDialog
      className={clsx('w-full max-w-md rounded-lg', className)}
      open={open}
      onClose={onClose}
      {...others}
    >
      <h5>2 Factor Authentication</h5>
      <div className='text-subtitle'>Configure 2fa in your google authenticator.</div>
      <div className='flex flex-col gap-4'>
        <div className='mt-6 flex flex-col items-center gap-2'>
          <div className='h-32 w-32 border border-white/10'>
            {QRCodeSrc && <img className='h-full w-full' src={QRCodeSrc} alt='2fa' />}
          </div>
          <div className='text-sm text-subtitle'>
            Secret: <span className='text-title'>{secret}</span>
          </div>
        </div>
        {errMsg && <span className='text-error'>{errMsg}</span>}
        <div>
          <label className='text-sm text-subtitle'>Login Password *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='text-sm py-2'
            name='password'
            autoComplete='off'
            type='password'
            value={data.password}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className='text-sm text-subtitle'>Two FA code *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='text-sm py-2'
            name='twoFA'
            maxLength={6}
            autoComplete='off'
            value={data.twoFA}
            onChange={handleInputChange}
          />
        </div>
        <GradientButton className='mt-2 rounded-md' disabled={!canApply} onClick={handleApply}>
          Enable Two FA
        </GradientButton>
      </div>
    </BaseDialog>
  )
}

export default TwoFADialog
