import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import _ from 'lodash'

import $scope from 'src/libs/scope'
import Storage from 'src/libs/storage'

import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'
import BaseSwitch from 'src/components/inputs/BaseSwitch'
import BaseTooltip from 'src/pages/Home/components/BaseTooltip'
import { PairInfo } from 'src/pages/Home/components/SettingDetail'

import { AppDispatch } from 'src/store'
import {
  selectCustomStratEditors,
  selectGunbotMarketType,
  selectGunbotPairOverride,
  selectGunbotStrategy,
  setPairOverride,
} from 'src/store/bots'
import { CustomStratEditors, StratSetting } from 'src/store/bots/bots.api'

const easyStratEditor = $scope.easyStratEditor as unknown as CustomStratEditors

type Props = {
  rowData: PairInfo
  index?: number
}

const titles = $scope.title as { [key: string]: string }

function TableDetailPanelOfPair({ rowData }: Props) {
  const { url, exchange, pair, strategy } = rowData
  const dispath = useDispatch<AppDispatch>()
  const pairOverride = useSelector(selectGunbotPairOverride(url, exchange, pair), _.isEqual)
  const strategyData = useSelector(selectGunbotStrategy(url, strategy), _.isEqual)
  const marketType = useSelector(selectGunbotMarketType(url, exchange), _.isEqual)
  const customStratEditors = useSelector(selectCustomStratEditors(url), _.isEqual)

  const allTemplates: CustomStratEditors = useMemo(() => {
    const temp: CustomStratEditors = {}
    if (!_.isNil(customStratEditors)) {
      Object.keys(customStratEditors).forEach((element) => {
        const formatted = element.toLowerCase()
        temp[formatted] = {}
        temp[formatted]['Pair name'] = {
          description: 'Set which pair to trade',
          parameters: {
            PAIR_NAME: {
              defaultValue: 'USDT-BTC',
              tooltip: `Gunbot uses a standardized format for entering trading pairs, to allow a uniform way of adding pairs across platforms. Exchanges often use a different notation.
  
      The general format is: BASECOIN-QUOTECOIN
  
      All pairs with BTC as base currency are written like: BTC-ETH, BTC-DOGE, BTC-XRP
      With a BTC-XXX pair, the aim is realize growth in BTC balance.
  
      All pairs with USDT as base currency are written like: USDT-BTC, USDT-ETH, USDT-XMR
      With an USDT-XXX pair, the aim is realize growth in USDT balance.`,
              inputType: 'string',
            },
          },
        }
        temp[formatted] = { ...temp[formatted], ...customStratEditors[element] }
        temp[formatted].info = {
          ...(temp[formatted].info || {}),
          license: [
            'ultimate',
            'promoultimate',
            'br',
            'mm',
            'promostandard',
            'standard',
            'pro',
            'promopro',
            'standardtv',
            'protv',
            'monthlyStandard',
            'yearlyStandard',
            'monthlyPro',
            'yearlyPro',
            'monthlyUltimate',
            'yearlyUltimate',
          ],
        }
      })
    }
    return { ...$scope.easyStratEditor, ...temp } as unknown as CustomStratEditors
  }, [customStratEditors])

  const { buyMethod, sellMethod } = useMemo(() => {
    const buyMethod: string = (pairOverride.BUY_METHOD ||
      strategyData.BUY_METHOD ||
      strategy) as string

    const sellMethod: string = (pairOverride.SELL_METHOD ||
      strategyData.SELL_METHOD ||
      strategy) as string
    return { buyMethod, sellMethod }
  }, [pairOverride, strategyData, strategy])

  const { keysOfSettings } = useMemo(() => {
    const strategyName =
      Object.keys(allTemplates).includes(strategy) &&
      !Object.keys($scope.easyStratEditor).includes(strategy)
        ? strategy
        : getRelevantEditorReference(sellMethod, marketType, strategy)

    const keysOfSettings = Object.keys(allTemplates[strategyName]).filter((item) => {
      if (
        item === 'info' ||
        item === 'Pair name' ||
        item === 'Backfesting' ||
        item === 'Backtesting'
      ) {
        return false
      }
      return true
    })

    return { keysOfSettings }
  }, [strategy, allTemplates, sellMethod, marketType])

  const [selectedKeyOfSetting, setSelectedKeyOfSetting] = useState<string>(
    Storage.get(`${url}/${exchange}/${pair}/selectedKeyOfSetting`) || keysOfSettings[0] || '',
  )
  const handleChangeSelectedKeyOfSetting = useCallback(
    (settingName: string) => {
      setSelectedKeyOfSetting(settingName)
      Storage.set(`${url}/${exchange}/${pair}/selectedKeyOfSetting`, settingName)
    },
    [url, exchange, pair],
  )

  const handleOverrideSwitchChange = useCallback(
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      dispath(setPairOverride({ url, exchange, pair, name, value: e.target.checked }))
    },
    [url, exchange, pair],
  )

  const handleOverrideChange = useCallback(
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      dispath(setPairOverride({ url, exchange, pair, name, value: e.target.value }))
    },
    [url, exchange, pair],
  )
  let displayBuyMethod = buyMethod
  let displaySellMethod = sellMethod
  let strategyKey = strategy

  // set safe default buy method for use in editor for mixed buy/sell methods
  if (!isReservedStrategy(strategy, allTemplates)) {
    displayBuyMethod = strategyData.BUY_METHOD === buyMethod ? strategyData.BUY_METHOD : buyMethod
    displaySellMethod =
      strategyData.SELL_METHOD === sellMethod ? strategyData.SELL_METHOD : sellMethod
    const isCustomStrat =
      Object.keys(allTemplates).includes(strategy) &&
      !Object.keys($scope.easyStratEditor).includes(strategy)
    if (isCustomStrat) {
      strategyKey = strategy
    } else if (isReservedMethod(sellMethod)) {
      if (sellMethod === 'market_maker' && !marketType.includes('spot')) {
        strategyKey = 'market_maker (futures)'
      } else {
        strategyKey = sellMethod
      }
    } else {
      if (marketType.includes('spot')) {
        strategyKey = 'builder'
      } else {
        strategyKey = 'builder (futures)'
      }
    }
  }

  return (
    <div className='flex h-[80vh] min-h-96 gap-3 2md:h-96'>
      <div className='w-52 rounded-lg border border-white/5 p-3 md:w-72'>
        <div className='mt-1 flex w-full flex-auto flex-col gap-4'>
          {keysOfSettings.map((settingName, index) => {
            const isSelected = selectedKeyOfSetting === settingName
            return (
              <div
                key={index}
                className={clsx('flex cursor-pointer items-center gap-2 rounded-md p-3', {
                  'bg-[#2b2e35]': isSelected,
                })}
                onClick={() => handleChangeSelectedKeyOfSetting(settingName)}
              >
                <span className={clsx({ 'gradient-text': isSelected })}>{settingName}</span>
              </div>
            )
          })}
        </div>
      </div>
      <div className='flex-auto overflow-y-auto rounded-lg border border-white/5 p-3'>
        <div className='flex flex-col items-start gap-1 pb-2'>
          <span className='gradient-text text-xl font-bold'>{selectedKeyOfSetting}</span>
          <div className='text-sm text-[#848e9c]'>
            {allTemplates[strategyKey][selectedKeyOfSetting]?.description || ''}
          </div>
        </div>
        <div className='mt-1 grid w-full flex-auto gap-4 rounded-lg border border-white/5 p-4 sm:max-h-96 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5'>
          {(function () {
            let parameterData: { [key: string]: StratSetting }
            let parameters: string[]
            if (strategyKey === 'builder' || strategyKey.includes('RSIOMA')) {
              if (strategyKey.includes('RSIOMA')) {
                displayBuyMethod = 'gain'
                displaySellMethod = 'gain'
              }
              if (selectedKeyOfSetting === 'Buy settings') {
                parameters =
                  Object.keys(allTemplates.builder['Buy settings']?.parameters[displayBuyMethod]) ||
                  []
                parameterData = allTemplates.builder['Buy settings']?.parameters?.[
                  displayBuyMethod
                ] as unknown as { [key: string]: StratSetting }
              } else if (selectedKeyOfSetting === 'Sell settings') {
                parameters =
                  Object.keys(
                    allTemplates.builder['Sell settings']?.parameters[displaySellMethod],
                  ) || []
                parameterData = allTemplates.builder['Sell settings']?.parameters?.[
                  displaySellMethod
                ] as unknown as { [key: string]: StratSetting }
              } else if (selectedKeyOfSetting === 'Indicators') {
                const buyIndicatorSettings = Object.keys(
                  allTemplates.builder['Indicators']?.parameters[displayBuyMethod],
                )
                const sellIndicatorSettings = Object.keys(
                  allTemplates.builder['Indicators']?.parameters[displaySellMethod],
                )
                parameters = _.union(buyIndicatorSettings, sellIndicatorSettings)
                parameterData = {
                  ...allTemplates.builder['Indicators']?.parameters[displayBuyMethod],
                  ...allTemplates.builder['Indicators']?.parameters[displaySellMethod],
                } as unknown as { [key: string]: StratSetting }
              } else {
                // console.log(allTemplates, strategyKey, item)
                parameters = Object.keys(
                  allTemplates[strategyKey][selectedKeyOfSetting]?.parameters,
                )
                parameterData = allTemplates[strategyKey][selectedKeyOfSetting]?.parameters
              }
            } else if (strategyKey === 'builder (futures)') {
              if (selectedKeyOfSetting === 'Long settings') {
                parameters =
                  Object.keys(
                    allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[displayBuyMethod],
                  ) || []
                parameterData = allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[
                  displayBuyMethod
                ] as unknown as { [key: string]: StratSetting }
              } else if (selectedKeyOfSetting === 'Short settings') {
                parameters =
                  Object.keys(
                    allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[
                      displaySellMethod
                    ],
                  ) || []
                parameterData = allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[
                  displaySellMethod
                ] as unknown as { [key: string]: StratSetting }
              } else if (selectedKeyOfSetting === 'Close position') {
                const longMethodSettings = Object.keys(
                  allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[displayBuyMethod],
                )
                const shortMethodSettings = Object.keys(
                  allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[displaySellMethod],
                )
                parameters = _.union(longMethodSettings, shortMethodSettings)
                parameterData = {
                  ...allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[
                    displayBuyMethod
                  ],
                  ...allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[
                    displaySellMethod
                  ],
                } as unknown as { [key: string]: StratSetting }
              } else if (selectedKeyOfSetting === 'Stop loss') {
                const longMethodSettings = Object.keys(
                  allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[displayBuyMethod],
                )
                const shortMethodSettings = Object.keys(
                  allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[displaySellMethod],
                )
                parameters = _.union(longMethodSettings, shortMethodSettings)
                parameterData = {
                  ...allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[
                    displayBuyMethod
                  ],
                  ...allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[
                    displaySellMethod
                  ],
                } as unknown as { [key: string]: StratSetting }
              } else if (selectedKeyOfSetting === 'Indicators') {
                const longMethodSettings = Object.keys(
                  allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[displayBuyMethod],
                )
                const shortMethodSettings = Object.keys(
                  allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[displaySellMethod],
                )
                parameters = _.union(longMethodSettings, shortMethodSettings)
                parameterData = {
                  ...allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[
                    displayBuyMethod
                  ],
                  ...allTemplates[strategyKey][selectedKeyOfSetting]?.parameters?.[
                    displaySellMethod
                  ],
                } as unknown as { [key: string]: StratSetting }
              } else {
                parameters = Object.keys(
                  allTemplates[strategyKey][selectedKeyOfSetting]?.parameters,
                )
                parameterData = allTemplates[strategyKey][selectedKeyOfSetting]?.parameters
              }
            } else {
              const isHedgeLong = pair.endsWith('-LONG')
              const isHedgeShort = pair.endsWith('-SHORT')
              const isHeadline = allTemplates[strategyKey][selectedKeyOfSetting]?.isHeadline || null
              // console.log(allTemplates, strategyKey, item, isCustomStrat)
              parameters = Object.keys(
                allTemplates?.[strategyKey]?.[selectedKeyOfSetting]?.parameters || [],
              )
              parameterData = allTemplates[strategyKey][selectedKeyOfSetting]?.parameters

              if (
                isHedgeLong &&
                (selectedKeyOfSetting === 'Short alerts' ||
                  selectedKeyOfSetting === 'Close short alerts' ||
                  selectedKeyOfSetting === 'Flip long alerts' ||
                  selectedKeyOfSetting === 'Flip short alerts')
              ) {
                return null
              }
              if (
                isHedgeShort &&
                (selectedKeyOfSetting === 'Long alerts' ||
                  selectedKeyOfSetting === 'Close long alerts' ||
                  selectedKeyOfSetting === 'Flip long alerts' ||
                  selectedKeyOfSetting === 'Flip short alerts')
              ) {
                return null
              }

              if (isHeadline) {
                return (
                  <h2 key={selectedKeyOfSetting} className='p-4 text-xl font-semibold'>
                    {selectedKeyOfSetting}
                  </h2>
                )
              }
            }

            return parameters.map((settingName) => {
              if (settingName.includes('separator')) {
                return null
              }
              const exchangeSpecific = parameterData[settingName]?.exchangeSpecific
              if (exchangeSpecific && exchangeSpecific.indexOf(stripAppendix(exchange)) < 0) {
                return null
              }
              const tooltips = $scope.tooltips as unknown as { [key: string]: string }
              const option = parameterData[settingName]
              const id = `override-${url}/${exchange}/${pair}/${settingName}`
              const tooltip = parameterData[settingName]?.tooltip || tooltips[settingName] || ''
              return (
                <div
                  key={settingName}
                  className='group relative flex items-center gap-2 rounded-md border border-white/10 bg-[#2b2e35] p-3'
                >
                  <div className='flex flex-auto flex-col gap-2'>
                    <label htmlFor={id} className='word-break flex items-center gap-1'>
                      {titles[settingName] || settingName}
                      <BaseTooltip tooltip={tooltip} />
                    </label>
                    <div>
                      {option?.inputType === 'boolean' ||
                      typeof pairOverride[settingName] === 'boolean' ? (
                        <BaseSwitch
                          id={id}
                          checked={Boolean(pairOverride[settingName])}
                          onChange={handleOverrideSwitchChange(settingName)}
                        />
                      ) : (
                        <BaseRoundedInput
                          id={id}
                          className='w-full rounded-md'
                          value={pairOverride[settingName] as string}
                          onChange={handleOverrideChange(settingName)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )
            })
          })()}
        </div>
      </div>
    </div>
  )
}

const isReservedStrategy = function (strategyName: string, allTemplates: CustomStratEditors) {
  if (Object.keys(allTemplates).indexOf(strategyName) < 0) {
    return false
  } else {
    return true
  }
}

// reserved trading methods require strategy editor with identical buy and sell method
const isReservedMethod = function (tradingMethod: string) {
  if (_.isNil(tradingMethod)) {
    return false
  } else if (
    tradingMethod === 'stepgrid' ||
    tradingMethod === 'spotgrid' ||
    tradingMethod === 'spotgridadvanced' ||
    tradingMethod === 'stepgridhybrid' ||
    tradingMethod === 'stepgridscalp' ||
    tradingMethod === 'tenkan' ||
    tradingMethod === 'futuresgrid' ||
    tradingMethod === 'market_maker' ||
    tradingMethod === 'custom' ||
    tradingMethod === 'gridbot' ||
    tradingMethod === 'gridbotadvanced' ||
    tradingMethod === 'stepgridhedge' ||
    tradingMethod === 'sgsfutures' ||
    tradingMethod === 'sgsnextgen' ||
    tradingMethod === 'channelmaestro'
  ) {
    return true
  } else {
    return false
  }
}

const getRelevantEditorReference = function (
  sellMethod: string,
  marketType: string,
  strategy: string,
) {
  let result

  const editorTemplate = easyStratEditor?.[strategy] ? easyStratEditor?.[strategy] : {}
  if (editorTemplate?.info?.category === 'Community Devs') {
    result = strategy
  } else if (
    isReservedMethod(sellMethod) &&
    sellMethod === 'market_maker' &&
    marketType.includes('spot')
  ) {
    result = 'market_maker'
  } else if (
    isReservedMethod(sellMethod) &&
    sellMethod === 'market_maker' &&
    !marketType.includes('spot')
  ) {
    result = 'market_maker (futures)'
  } else if (isReservedMethod(sellMethod)) {
    result = sellMethod
  } else if (marketType.includes('spot')) {
    if (strategy === 'Double CCI+RSIOMA') {
      return strategy
    } else if (strategy.includes('webhooks_trading_terminal')) {
      return strategy
    } else {
      result = 'builder'
    }
  } else {
    if (strategy.includes('RSIOMA')) {
      return strategy
    } else if (strategy.includes('webhooks_trading_terminal')) {
      return strategy
    }
    result = 'builder (futures)'
  }
  return result
}

function stripAppendix(str: string) {
  const exchange = str || ''
  return exchange.replace(/#\d+$/, '')
}

export default TableDetailPanelOfPair
