import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

import { RejectedValue } from 'src/libs/error.handler'
import eventEmitter from 'src/libs/event.emitter'

import GradientButton from 'src/components/buttons/GradientButton'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'

import { AppDispatch } from 'src/store'
import { selectIsRegistred, signupAction } from 'src/store/auth'
import { SignupReq } from 'src/store/auth/auth.api'

function SignupPage() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const registred = useSelector(selectIsRegistred)
  const [{ errors, message: errMsg }, setError] = useState<RejectedValue>({})
  const [data, setData] = useState<SignupReq>({
    email: '',
    password: '',
    repeatPassword: '',
  })

  const handleSignup = useCallback(() => {
    dispatch(signupAction(data))
  }, [data])

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setData((prev) => ({ ...prev, [name]: value }))
  }, [])

  useEffect(() => {
    const failedCallback = (error: RejectedValue) => setError(error)
    const successCallback = () => {
      setError({})
      navigate('/login')
    }

    eventEmitter.on(signupAction.rejected.type, failedCallback)
    eventEmitter.on(signupAction.fulfilled.type, successCallback)

    return () => {
      eventEmitter.off(signupAction.rejected.type, failedCallback)
      eventEmitter.off(signupAction.fulfilled.type, successCallback)
    }
  }, [])

  if (registred) {
    return <Navigate to='/login' replace />
  }

  return (
    <div className='m-auto flex w-full max-w-96 flex-col gap-4 px-4'>
      <div className='text-center'>
        <h4 className='mb-4'>Sign up</h4>
        <div className='text-subtitle'>Welcome to gunbot monitor!</div>
      </div>
      <div className='flex flex-col gap-1'>
        <label htmlFor='signup-email' className='text-subtitle'>
          Email *
        </label>
        <BaseRoundedInput
          id='signup-email'
          className='rounded-md'
          inputClassName='h-9 text-sm'
          value={data.email}
          name='email'
          onChange={handleInputChange}
        />
        {errMsg && <span className='text-error'>{errMsg}</span>}
        {errors?.email && <span className='text-error'>{errors.email[0].message}</span>}
      </div>
      <div className='flex flex-col gap-1'>
        <label htmlFor='signup-password' className='text-subtitle'>
          Password *
        </label>
        <BaseRoundedInput
          id='signup-password'
          className='rounded-md'
          inputClassName='h-9 text-sm'
          type='password'
          value={data.password}
          name='password'
          onChange={handleInputChange}
        />
        {errors?.password && <span className='text-error'>{errors.password[0].message}</span>}
      </div>
      <div className='flex flex-col gap-1'>
        <label htmlFor='signup-repeat-password' className='text-subtitle'>
          Repeat Password *
        </label>
        <BaseRoundedInput
          id='signup-repeat-password'
          className='rounded-md'
          inputClassName='h-9 text-sm'
          type='password'
          value={data.repeatPassword}
          name='repeatPassword'
          onChange={handleInputChange}
        />
        {errors?.repeatPassword && (
          <span className='text-error'>{errors.repeatPassword[0].message}</span>
        )}
      </div>
      <GradientButton className='mt-4 rounded-full' fontSize='lg' onClick={handleSignup}>
        Signup
      </GradientButton>
    </div>
  )
}

export default SignupPage
