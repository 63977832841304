import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { io } from 'socket.io-client'

import { AppDispatch } from 'src/store'
import { removeBot } from 'src/store/bots'
import { BotReq } from 'src/store/bots/bots.api'

const socket = io('/', { autoConnect: true })

function SocketProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const kickBot = (bot: BotReq) => {
      dispatch(removeBot(bot))
    }
    socket.on('Bot::invalid_gunthy_wallet', kickBot)

    return () => {
      socket.off('Bot::invalid_gunthy_wallet', kickBot)
    }
  }, [])

  return <>{children}</>
}

export default SocketProvider
