import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

import eventEmitter from 'src/libs/event.emitter'

import BotLoginDialog from 'src/providers/BotLoginProvider/BotLoginDialog'

import { AppDispatch } from 'src/store'
import { loginGunbotAction, logoutGunbotAction } from 'src/store/bots'
import { BotRes } from 'src/store/bots/bots.api'

type GunbotAuthHandler = (bot: BotRes, isLogin: boolean, onSuccess?: (bot: BotRes) => void) => void

const BotLoginContext = createContext<GunbotAuthHandler>(() => {})

function BotLoginProvider({ children }: { children: ReactNode }) {
  const [bot, setBot] = useState<BotRes>()
  const [onSuccess, setOnSuccess] = useState<(bot: BotRes) => void>()
  const dispatch = useDispatch<AppDispatch>()

  const handleLogin: GunbotAuthHandler = useCallback((bot, isLogin, onSuccess) => {
    if (isLogin) {
      setBot(bot)
    } else {
      dispatch(logoutGunbotAction(bot))
    }
    setOnSuccess(() => onSuccess)
  }, [])

  const handleClose = useCallback(() => setBot(undefined), [])

  useEffect(() => {
    if (onSuccess && bot) {
      const callback = () => {
        onSuccess(bot)
      }
      eventEmitter.on(logoutGunbotAction.fulfilled.type, callback)
      eventEmitter.on(loginGunbotAction.fulfilled.type, callback)
      return () => {
        eventEmitter.off(logoutGunbotAction.fulfilled.type, callback)
        eventEmitter.off(loginGunbotAction.fulfilled.type, callback)
      }
    }
  }, [onSuccess, bot])

  return (
    <BotLoginContext.Provider value={handleLogin}>
      {children}
      {bot && <BotLoginDialog open={!!bot} bot={bot} onClose={handleClose} />}
    </BotLoginContext.Provider>
  )
}

export default BotLoginProvider

export const useGunbotAuth = () => {
  return useContext(BotLoginContext)
}
