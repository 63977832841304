import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import GradientButton from 'src/components/buttons/GradientButton'

import { AppDispatch } from 'src/store'
import { disableTwoFAAction, selectRequireTwoFA } from 'src/store/auth'

import { ReactComponent as TwoFAIcon } from 'src/assets/icons/2fa-icon.svg'

import OnOrOff from './OnOrOff'
import TwoFADialog from './TwoFADialog'

import { BaseDivProps } from 'src/types'

function TwoFASetting({ className }: BaseDivProps) {
  const dispatch = useDispatch<AppDispatch>()
  const requireTwoFA = useSelector(selectRequireTwoFA)
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])
  const handleDisable = useCallback(() => {
    dispatch(disableTwoFAAction())
  }, [])

  return (
    <div
      className={clsx(
        'flex flex-col gap-4 md:flex-row md:items-center md:justify-between',
        className,
      )}
    >
      <div className='flex flex-col gap-1'>
        <div className='flex gap-2'>
          <TwoFAIcon className='min-w-[18px] scale-110' width={18} />
          <div className='font-bold'>Two-Factor Authentication (2FA)</div>
        </div>
        <div className='text-subtitle md:pl-6'>
          Use Google Authenticator to protect your account and bots.
        </div>
      </div>
      <div className='flex flex-row-reverse items-center justify-end gap-4 md:flex-row md:gap-8'>
        <OnOrOff enabled={requireTwoFA} className='w-14' />
        {requireTwoFA ? (
          <GradientButton size='sm' className='rounded-md' onClick={handleDisable}>
            Disable
          </GradientButton>
        ) : (
          <GradientButton size='sm' className='rounded-md' onClick={handleOpen}>
            Enable
          </GradientButton>
        )}
      </div>
      {!requireTwoFA && <TwoFADialog open={open} onClose={handleClose} />}
    </div>
  )
}

export default TwoFASetting
