import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Decimal from 'decimal.js-light'

import { numFormatter } from 'src/libs/helper'
import GradientButton from 'src/components/buttons/GradientButton'
import GradientWrapper from 'src/components/wrappers/GradientWrraper'
import GaugeCharts from 'src/pages/Home/components/GaugeCharts'
import { AppDispatch } from 'src/store'
import {
  deleteBotAction,
  selectAllPnlHistory,
  startGunbotAction,
  stopGunbotAction,
} from 'src/store/bots'
import { BotRes } from 'src/store/bots/bots.api'
import AddBotDialog from './components/AddBotDialog'
import BotsTableComponent from './components/BotsTableComponent'
import EditBotDialog from './components/EditBotDialog'

function HomePage() {
  const dispatch = useDispatch<AppDispatch>()
  const allPnlHistory = useSelector(selectAllPnlHistory)
  const numOfRunning = Object.keys(allPnlHistory).length

  const { today, yesterday, yearAgo, monthAgo, weekAgo, unit } = useMemo(() => {
    const data = {
      today: 0,
      yesterday: 0,
      weekAgo: 0,
      monthAgo: 0,
      yearAgo: 0,
      unit: 'USDT',
    }
    Object.keys(allPnlHistory).forEach((url) => {
      const pnlHistory = allPnlHistory[url]
      data.today = new Decimal(pnlHistory.today.pnl).plus(data.today).toNumber()
      data.yesterday = new Decimal(pnlHistory.yesterday.pnl).plus(data.yesterday).toNumber()
      data.weekAgo = new Decimal(pnlHistory.weekAgo.pnl).plus(data.weekAgo).toNumber()
      data.monthAgo = new Decimal(pnlHistory.monthAgo.pnl).plus(data.monthAgo).toNumber()
      data.yearAgo = new Decimal(pnlHistory.yearAgo.pnl).plus(data.yearAgo).toNumber()
    })
    return data
  }, [allPnlHistory])

  const todayGrow = new Decimal(today).minus(yesterday).toNumber()
  const todayGrowPercent = yesterday !== 0 ? +(((today - yesterday) * 100) / Math.abs(yesterday)).toFixed(1) : 0

  const [openAddBotDialog, setOpenAddBotDialog] = useState<boolean>(false)
  const [editBot, setEditBot] = useState<BotRes>()
  const [isCollapsed, setIsCollapsed] = useState(false) 
  const [sectionOrder, setSectionOrder] = useState(['combinedPnlSection', 'analysisSection', 'botsSection'])

  const openEditBotDialog = useCallback((bot: BotRes) => {
    setEditBot(bot)
  }, [])
  const closeEditBotDialog = useCallback(() => {
    setEditBot(undefined)
  }, [])
  const startGunbot = useCallback((bot: BotRes) => {
    dispatch(startGunbotAction(bot))
  }, [])
  const stopGunbot = useCallback((bot: BotRes) => {
    dispatch(stopGunbotAction(bot))
  }, [])
  const handleDeleteBot = useCallback((bot: BotRes) => {
    dispatch(deleteBotAction(bot.id))
  }, [])

  const handleOpenAddBotDialog = useCallback(() => setOpenAddBotDialog(true), [])
  const handleCloseAddBotDialog = useCallback(() => setOpenAddBotDialog(false), [])
  const toggleCollapse = () => setIsCollapsed(prev => !prev)

  const handleDragStart = (event: React.DragEvent, sectionId: string) => {
    event.dataTransfer.setData('text/plain', sectionId)
  }

  const handleDrop = (event: React.DragEvent, dropIndex: number) => {
    event.preventDefault()
    const draggedSectionId = event.dataTransfer.getData('text')
    setSectionOrder((currentOrder) => {
      const newOrder = currentOrder.filter(id => id !== draggedSectionId)
      newOrder.splice(dropIndex, 0, draggedSectionId)
      return newOrder
    })
  }

  const handleDragOver = (event: React.DragEvent) => event.preventDefault()

  return (
    <div className='h-full py-4'>
      {sectionOrder.map((sectionId, index) => (
        <div
          key={sectionId}
          draggable
          onDragStart={(event) => handleDragStart(event, sectionId)}
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, index)}
          style={{ cursor: 'move', marginBottom: '1rem' }}
        >
          {sectionId === 'combinedPnlSection' && (
            <div className='grid gap-3 2md:grid-cols-6 xl:grid-cols-5'>
              <GradientWrapper gradientOpacity={0.3} className='rounded-lg p-5 shadow-sm 2md:col-span-2'>
                <h6 className='inline text-2xl font-bold'>Today’s Profit and Loss</h6>
                <div className='mt-1 text-md text-subtitle'>
                  Total <span className='text-white'>{numOfRunning}</span> bots running
                </div>
                <h6 className='my-3 text-white'>
                  {numFormatter(today)} {unit}
                </h6>
                <GradientButton size='md' border={2} className='rounded-lg' onClick={handleOpenAddBotDialog}>
                  <span className='mb-[-5px] mr-1 mt-[-6px] text-[19px]'>+</span> Add Bot
                </GradientButton>
              </GradientWrapper>

              <GradientWrapper gradientOpacity={0.3} className='flex min-h-[183px] flex-col rounded-lg p-5 shadow-sm 2md:col-span-4 xl:col-span-3'>
                <div className='flex items-center gap-2'>
                  <h6 className='text-2xl font-bold'>PnL Statistics</h6>
                </div>
                <div className='mt-1 text-md text-subtitle'>
                  Today {todayGrowPercent >= 0 ? 'already' : 'still'}{' '}
                  <span className='text-white'>
                    {numFormatter(todayGrow)} {unit}
                  </span>{' '}
                  {todayGrowPercent >= 0 ? 'better than yesterday 😎' : 'below yesterday 😥'}
                </div>
                <div className='mt-10 grid gap-2 2xs:grid-cols-2 sm:grid-cols-4'>
                  <div className='col-span-1 flex gap-3'>
                    <GradientWrapper className='inline-flex h-12 w-12 items-center justify-center rounded-md font-bold text-primary'>
                      D
                    </GradientWrapper>
                    <div className='flex flex-col justify-between py-0.5 text-xs text-subtitle'>
                      <span className='text-xs'>Yesterday PnL</span>
                      <span>
                        <span className='text-sm text-title'>{numFormatter(yesterday)}</span> {unit}
                      </span>
                    </div>
                  </div>
                  <div className='col-span-1 flex gap-3'>
                    <GradientWrapper className='inline-flex h-12 w-12 items-center justify-center rounded-md font-bold text-primary'>
                      W
                    </GradientWrapper>
                    <div className='flex flex-col justify-between py-0.5 text-xs text-subtitle'>
                      <span className='text-xs'>Week PnL</span>
                      <span>
                        <span className='text-sm text-title'>{numFormatter(weekAgo)}</span> {unit}
                      </span>
                    </div>
                  </div>
                  <div className='col-span-1 flex gap-3'>
                    <GradientWrapper className='inline-flex h-12 w-12 items-center justify-center rounded-md font-bold text-primary'>
                      M
                    </GradientWrapper>
                    <div className='flex flex-col justify-between py-0.5 text-xs text-subtitle'>
                      <span className='text-xs'>Month PnL</span>
                      <span>
                        <span className='text-sm text-title'>{numFormatter(monthAgo)}</span> {unit}
                      </span>
                    </div>
                  </div>
                  <div className='col-span-1 flex gap-3'>
                    <GradientWrapper className='inline-flex h-12 w-12 items-center justify-center rounded-md font-bold text-primary'>
                      Y
                    </GradientWrapper>
                    <div className='flex flex-col justify-between py-0.5 text-xs text-subtitle'>
                      <span className='text-xs'>Year PnL</span>
                      <span>
                        <span className='text-sm text-title'>{numFormatter(yearAgo)}</span> {unit}
                      </span>
                    </div>
                  </div>
                </div>
              </GradientWrapper>
            </div>
          )}

          {sectionId === 'analysisSection' && (
            <div>
              <h5 className='mb-2 mt-10' onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
                Analysis{' '}
                <span style={{ fontSize: '0.6em', color: 'darkgoldenrod' }}>
                  {isCollapsed ? '▼' : '▲'}
                </span>
              </h5>
              <div className='mb-2 text-subtitle'>
                You can see ROI, Risk/Reward, and Capital Efficiency for all pairs running on all bots
              </div>
              {!isCollapsed && <GaugeCharts />}
            </div>
          )}

          {sectionId === 'botsSection' && (
            <div>
              <h5>Your bots</h5>
              <div className='mt-2 text-subtitle'>
                Monitor and Manage your bots running on different addresses
              </div>
              <GradientButton size='sm' className='w-full text-nowrap rounded-lg 2xs:w-auto' onClick={handleOpenAddBotDialog}>
                <span className='mb-[-5px] mr-1 mt-[-6px] text-[19px]'>+</span> Add Bot
              </GradientButton>
              <BotsTableComponent onEditBot={openEditBotDialog} onDeleteBot={handleDeleteBot} onStartGunbot={startGunbot} onStopGunbot={stopGunbot} />
            </div>
          )}
        </div>
      ))}

      <AddBotDialog open={openAddBotDialog} onClose={handleCloseAddBotDialog} />
      {editBot && <EditBotDialog open={!!editBot} bot={editBot} onClose={closeEditBotDialog} />}
    </div>
  )
}

export default HomePage
