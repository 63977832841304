import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch } from 'src/store'
import { loadBotsAction, selectBots } from 'src/store/bots'

import BotSocket from './BotSocket'

type Props = {
  children: React.ReactNode
}

function BotSocketProvider({ children }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const bots = useSelector(selectBots)

  useEffect(() => {
    dispatch(loadBotsAction())
  }, [])

  return (
    <>
      {bots.map((bot, index) => (
        <BotSocket key={index} bot={bot} />
      ))}
      {children}
    </>
  )
}

export default BotSocketProvider
