import React from 'react'
import clsx from 'clsx'

import { BaseDivProps } from 'src/types'

type Props = BaseDivProps

function BaseLinkButton({ className, children, ...others }: Props) {
  return (
    <div className={clsx('font-semibold text-subtitle2 hover:!text-gunthy', className)} {...others}>
      {children}
    </div>
  )
}

export default BaseLinkButton
