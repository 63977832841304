import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import errorHandler from 'src/libs/error.handler'
import Storage, { TOKEN_STORAGE_KEY } from 'src/libs/storage'

import {
  changePassword,
  disableTwoFA,
  enableTwoFA,
  getTwoFASecret,
  login,
  logout,
  signinExpiredSession,
  signup,
  tokenSignin,
  UserData,
} from './auth.api'
import { RootState } from '..'
import {
  createOrUpdateSmtpAction,
  createOrUpdateTelegramAction,
  toggleSmtpAction,
  toggleTelegramAction,
} from '../setttings'

export type AuthState = {
  userdata: UserData | null
  requireTwoFA: boolean
  registered?: boolean
  _token: string | null
}

const initialState: AuthState = {
  userdata: null,
  requireTwoFA: false,
  _token: Storage.get(TOKEN_STORAGE_KEY),
}

export const signupAction = createAsyncThunk('auth/signup', errorHandler(signup))
export const loginAction = createAsyncThunk('auth/login', errorHandler(login))
export const logoutAction = createAsyncThunk('auth/logout', errorHandler(logout))
export const tokenSigninAction = createAsyncThunk('auth/token-signin', errorHandler(tokenSignin))
export const signinExpiredSessionAction = createAsyncThunk(
  'auth/signinExpiredSession',
  errorHandler(signinExpiredSession),
)
export const changePasswordAction = createAsyncThunk(
  'auth/changePassword',
  errorHandler(changePassword),
)
export const getTwoFASecretAction = createAsyncThunk(
  'auth/getTwoFASecret',
  errorHandler(getTwoFASecret),
)
export const enableTwoFAAction = createAsyncThunk('auth/enableTwoFA', errorHandler(enableTwoFA))
export const disableTwoFAAction = createAsyncThunk('auth/disableTwoFA', errorHandler(disableTwoFA))

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.fulfilled, (state, action) => {
        state.userdata = action.payload.userdata
        state._token = action.payload.token
        state.registered = true
        state.requireTwoFA = action.payload.requireTwoFA
        Storage.set(TOKEN_STORAGE_KEY, 'Bearer ' + state._token)
      })
      .addCase(signupAction.fulfilled, (state) => {
        state.registered = true
      })
      .addCase(signinExpiredSessionAction.fulfilled, (state, action) => {
        state.userdata = action.payload.userdata
        state._token = action.payload.token
        state.requireTwoFA = action.payload.requireTwoFA
        Storage.set(TOKEN_STORAGE_KEY, 'Bearer ' + state._token)
      })
      .addCase(logoutAction.fulfilled, (state) => {
        state._token = null
        state.userdata = null
        Storage.remove(TOKEN_STORAGE_KEY)
      })
      .addCase(tokenSigninAction.fulfilled, (state, action) => {
        state.userdata = action.payload.userdata
        state.requireTwoFA = action.payload.requireTwoFA
        if (action.payload.token) {
          state._token = action.payload.token
          Storage.set(TOKEN_STORAGE_KEY, 'Bearer ' + state._token)
        }
        state.registered = true
      })
      .addCase(tokenSigninAction.rejected, (state, action) => {
        if (action.payload?.message === 'Empty user') {
          state.registered = false
        } else {
          state.registered = true
        }
      })
      .addCase(createOrUpdateSmtpAction.fulfilled, (state) => {
        if (state.userdata) {
          state.userdata.smtp = true
        }
      })
      .addCase(toggleSmtpAction.fulfilled, (state, action) => {
        if (state.userdata) {
          state.userdata.smtp = action.payload
        }
      })
      .addCase(createOrUpdateTelegramAction.fulfilled, (state) => {
        if (state.userdata) {
          state.userdata.telegram = true
        }
      })
      .addCase(toggleTelegramAction.fulfilled, (state, action) => {
        if (state.userdata) {
          state.userdata.telegram = action.payload
        }
      })
      .addCase(enableTwoFAAction.fulfilled, (state) => {
        state.requireTwoFA = true
      })
      .addCase(disableTwoFAAction.fulfilled, (state) => {
        state.requireTwoFA = false
      })
  },
})

const authReducer = authSlice.reducer
export default authReducer

export const selectIsRegistred = (state: RootState) => state.auth.registered
export const selectRequireTwoFA = (state: RootState) => state.auth.requireTwoFA
export const selectToken = (state: RootState) => state.auth._token
export const selectUserdata = (state: RootState) => state.auth.userdata
export const selectEmail = (state: RootState) => selectUserdata(state)?.email
export const selectIsSmtp = (state: RootState) => selectUserdata(state)?.smtp
export const selectIsTelegramEnabled = (state: RootState) => selectUserdata(state)?.telegram
