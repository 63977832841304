import React from 'react'
import GaugeComponent from 'react-gauge-component'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { colors } from 'src/libs/theme'

import { selectGunbotAllAnalysis } from 'src/store/bots'

const roiTickers = [
  { value: 0.5, label: 'Very bad' },
  { value: 1.5, label: 'Bad' },
  { value: 2.5, label: 'Normal' },
  { value: 3.5, label: 'Good' },
  { value: 4.5, label: 'Very Good' },
]

function GaugeCharts() {
  const { allROI, allRR, allCP } = useSelector(selectGunbotAllAnalysis, _.isEqual)

  return (
    <div className='flex flex-wrap-reverse justify-evenly gap-4'>
      <div className='w-full rounded-lg 2xs:w-auto xl:w-1/4 3xl:w-1/5'>
        <GaugeComponent
          value={allROI}
          maxValue={5}
          marginInPercent={{ top: 0.07, bottom: 0.0, left: 0.07, right: 0.07 }}
          type='radial'
          labels={{
            valueLabel: {
              formatTextValue(value: number) {
                return String(value)
              },
            },
            tickLabels: {
              type: 'inner',
              ticks: roiTickers,
              hideMinMax: true,
              defaultTickValueConfig: {
                style: {
                  color: 'white',
                  fontSize: '14px',
                },
                formatTextValue(val) {
                  return roiTickers.find(({ value }) => value === val)?.label || ''
                },
              },
            },
          }}
          arc={{
            cornerRadius: 7,
            colorArray: ['#EA4228', '#5BE12C'],
            subArcs: [{ limit: 1 }, { limit: 2 }, { limit: 3 }, { limit: 4 }, { limit: 5 }],
            padding: 0.03,
            width: 0.15,
          }}
          pointer={{
            elastic: true,
            animationDelay: 1,
            color: colors.success,
            type: 'needle',
            width: 15,
            length: 0.5,
          }}
        />
        <div className='text-center text-subtitle'>ROI</div>
      </div>
      <div className='w-full rounded-lg 2xs:w-auto xl:w-1/4 3xl:w-1/5'>
        <GaugeComponent
          type='radial'
          maxValue={5}
          marginInPercent={{ top: 0.075, bottom: 0.0, left: 0.07, right: 0.07 }}
          labels={{
            tickLabels: {
              type: 'inner',
              ticks: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
              defaultTickValueConfig: {
                style: {
                  color: 'white',
                  fontSize: '14px',
                },
              },
            },
          }}
          arc={{
            gradient: true,
            subArcs: [{ limit: 1 }, { limit: 2 }, { limit: 3 }, { limit: 4 }, { limit: 5 }],
            colorArray: ['#EA4228', '#5BE12C'],
            padding: 0,
            width: 0.15,
          }}
          pointer={{
            elastic: true,
            animationDelay: 1,
            color: colors.success,
            width: 15,
            length: 0.5,
          }}
          value={allRR}
        />
        <div className='text-center text-subtitle'>Risk / Reward</div>
      </div>
      <div className='w-full rounded-lg 2xs:w-auto xl:w-1/4 3xl:w-1/5'>
        <GaugeComponent
          value={allCP}
          maxValue={100}
          marginInPercent={{ top: 0.075, bottom: 0.0, left: 0.07, right: 0.07 }}
          type='radial'
          labels={{
            tickLabels: {
              type: 'inner',
              ticks: [{ value: 20 }, { value: 40 }, { value: 60 }, { value: 80 }, { value: 100 }],
              defaultTickValueConfig: {
                style: {
                  color: 'white',
                  fontSize: '14px',
                },
              },
            },
          }}
          arc={{
            colorArray: ['#EA4228', '#5BE12C'],
            padding: 0.0,
            cornerRadius: 0,
            width: 0.15,
            subArcs: [{ limit: 20 }, { limit: 40 }, { limit: 60 }, { limit: 80 }, { limit: 100 }],
          }}
          pointer={{
            elastic: true,
            color: colors.success,
            animationDelay: 1,
            type: 'needle',
            width: 15,
            length: 0.5,
          }}
        />
        <div className='text-center text-subtitle'>Capital Utilization Efficiency</div>
      </div>
    </div>
  )
}

export default GaugeCharts
