export const colors = {
  gunthy: '#fcd535',
  primary: '#ffc83d',
  info: 'rgb(59 130 246)',
  error: 'rgb(248 113 113)',
  warning: 'rgb(250 204 21)',
  success: 'rgb(45 212 191)',
  dark: '#181a20',
  light: '#ffffff',
  title: '#eaecef',
  subtitle: '#848e9c',
  subtitle2: '#b7bdc6',
  mainBg: '#181a20',
}
