import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import _ from 'lodash'

import eventEmitter from 'src/libs/event.emitter'

import GradientButton from 'src/components/buttons/GradientButton'
import BaseDialog, { BaseDialogProps } from 'src/components/dialogs/BaseDialog'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'

import { AppDispatch } from 'src/store'
import { createOrUpdateTelegramAction, selectTelegram } from 'src/store/setttings'
import { TelegramReq } from 'src/store/setttings/settings.api'

type Props = BaseDialogProps

function TelegramDialog({ className, onClose, ...others }: Props) {
  const dispatch = useDispatch<AppDispatch>()

  const originTelegram = useSelector(selectTelegram)
  const [telegram, setTelegram] = useState<TelegramReq>({
    chatId: '',
    botToken: '',
  })

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setTelegram((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleSave = useCallback(() => dispatch(createOrUpdateTelegramAction(telegram)), [telegram])

  useEffect(() => {
    if (originTelegram) {
      setTelegram(originTelegram)
    }
  }, [originTelegram])

  useEffect(() => {
    const onSuccess = () => onClose?.()
    eventEmitter.on(createOrUpdateTelegramAction.fulfilled.type, onSuccess)

    return () => {
      eventEmitter.off(createOrUpdateTelegramAction.fulfilled.type, onSuccess)
    }
  }, [onClose])

  const isChanged = !_.isEqual(originTelegram, telegram) && telegram.chatId && telegram.botToken

  return (
    <BaseDialog
      className={clsx('w-full max-w-md rounded-lg', className)}
      onClose={onClose}
      {...others}
    >
      <h5>Telegram bot</h5>
      <div className='text-subtitle'>Input your own telegram bot credentials.</div>
      <div className='mt-4 flex flex-col gap-4'>
        <div>
          <label className='text-sm text-subtitle'>Chat id *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='text-sm py-2'
            name='chatId'
            autoComplete='off'
            value={telegram.chatId}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className='text-sm text-subtitle'>Bot token *</label>
          <BaseRoundedInput
            className='rounded-md'
            inputClassName='text-sm py-2'
            autoComplete='off'
            name='botToken'
            value={telegram.botToken}
            onChange={handleInputChange}
          />
        </div>
        <GradientButton className='mt-2 rounded-md' disabled={!isChanged} onClick={handleSave}>
          Save
        </GradientButton>
      </div>
    </BaseDialog>
  )
}

export default TelegramDialog
