import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

import { RejectedValue } from 'src/libs/error.handler'
import eventEmitter from 'src/libs/event.emitter'
import { urlFromBot } from 'src/libs/helper'

import GradientButton from 'src/components/buttons/GradientButton'
import BaseDialog from 'src/components/dialogs/BaseDialog'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'

import { AppDispatch } from 'src/store'
import { loginGunbotAction } from 'src/store/bots'
import { BotRes } from 'src/store/bots/bots.api'

type Props = {
  open: boolean
  bot: BotRes
  onClose: () => void
}

type GunbotAuthData = {
  password: string
  twoFA?: string
}

function BotLoginDialog({ bot, open, onClose }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const [{ message: errMsg }, setError] = useState<RejectedValue>({})
  const twoFARef = useRef<HTMLInputElement>(null)

  const [data, setData] = useState<GunbotAuthData>({ password: '', twoFA: '' })

  const handleFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select()
  }, [])

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setData((prev) => ({ ...prev, [name]: value }))
  }, [])

  const onKeyEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name
      if (name === 'password') {
        if (bot.isTwoFA && twoFARef.current) {
          twoFARef.current.focus()
          twoFARef.current.select()
        } else if (e.currentTarget.value !== '') {
          handleLogin()
        }
      }
    },
    [data, bot.isTwoFA],
  )

  const handleLogin = useCallback(() => {
    dispatch(loginGunbotAction({ bot, req: data }))
  }, [bot, data])

  useEffect(() => {
    const onFailed = (error: RejectedValue) => {
      setError(error)
    }
    const onSuccess = () => {
      setError({})
      onClose()
    }
    eventEmitter.on(loginGunbotAction.fulfilled.type, onSuccess)
    eventEmitter.on(loginGunbotAction.rejected.type, onFailed)

    return () => {
      eventEmitter.off(loginGunbotAction.fulfilled.type, onSuccess)
      eventEmitter.off(loginGunbotAction.rejected.type, onFailed)
    }
  }, [])

  return (
    <BaseDialog open={open} onClose={onClose} className='w-full max-w-md rounded-lg'>
      <div className='flex flex-col gap-4'>
        <div>
          <h6 className='mb-2 text-2xl'>
            Login to the <span className='text-primary'>{bot.name}</span> Bot
          </h6>
          <div className='text-subtitle'>{urlFromBot(bot)}</div>
        </div>

        <div className='flex flex-col gap-2'>
          <label className='text-subtitle' htmlFor='gunbot-password'>
            Password *
          </label>
          <BaseRoundedInput
            id='gunbot-password'
            className='rounded-md'
            inputClassName='h-9 text-sm'
            type='password'
            name='password'
            autoFocus={open}
            onFocus={handleFocus}
            onChange={handleInputChange}
            value={data.password}
            onKeyEnter={onKeyEnter}
          />
          {errMsg && <span className='text-error'>{errMsg}</span>}
        </div>

        {bot.isTwoFA && (
          <div className='flex flex-col gap-2'>
            <label className='text-subtitle' htmlFor='gunbot-2fa'>
              2fa code *
            </label>
            <BaseRoundedInput
              ref={twoFARef}
              id='gunbot-2fa'
              className='rounded-md'
              inputClassName='h-9 text-sm'
              name='twoFA'
              onFocus={handleFocus}
              onChange={handleInputChange}
              value={data.twoFA}
              onKeyEnter={onKeyEnter}
            />
          </div>
        )}

        <GradientButton className='mt-4 rounded-full' fontSize='md' onClick={handleLogin}>
          Login
        </GradientButton>
      </div>
    </BaseDialog>
  )
}

export default BotLoginDialog
