import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import eventEmitter from 'src/libs/event.emitter'
import { urlFromBot } from 'src/libs/helper'

import { useGunbotAuth } from 'src/providers/BotLoginProvider'
import GradientButton from 'src/components/buttons/GradientButton'
import BaseTable, { Column } from 'src/components/tables/BaseTable'
import GradientWrapper from 'src/components/wrappers/GradientWrraper'

import { AppDispatch } from 'src/store'
import {
  DownloadStatus,
  getLatestVersionAction,
  selectBots,
  selectGunbotUpgradingStatus,
  upgradeGunbotAction,
} from 'src/store/bots'
import { BotRes } from 'src/store/bots/bots.api'

type UpgradingDataItem = { downloadStatus?: DownloadStatus } & BotRes

function UpgradePage() {
  const dispatch = useDispatch<AppDispatch>()
  const bots = useSelector(selectBots)
  const allUpgradingStatus = useSelector(selectGunbotUpgradingStatus)
  const [latestVersion, setLatestVersion] = useState('')
  const handleGunbotAuth = useGunbotAuth()

  const handleUpgrade = useCallback((bot: BotRes) => {
    if (!bot.loggedIn) {
      handleGunbotAuth(bot, true, (_) => {
        dispatch(upgradeGunbotAction(urlFromBot(bot)))
      })
    } else {
      dispatch(upgradeGunbotAction(urlFromBot(bot)))
    }
  }, [])

  const columns = useMemo(() => {
    const columns: Column<UpgradingDataItem>[] = [
      {
        label: 'Online',
        field: 'alive',
        headerClass: 'py-2 xl:py-3 px-2 xl:px-4 text-center',
        cellClass: 'py-2 xl:py-3 px-2 xl:px-4 text-center',
        render: ({ alive }) => {
          return (
            <span
              className={clsx('inline-block h-2 w-2 rounded-full', {
                'bg-success': alive,
                'bg-error': !alive,
              })}
            />
          )
        },
      },
      {
        label: 'Name',
        field: 'name',
        headerClass: 'py-2 xl:py-3 px-2 xl:px-4 text-center',
        cellClass: 'py-2 xl:py-3 px-2 xl:px-4 text-center',
      },
      {
        label: 'Url',
        headerClass: 'py-2 xl:py-3 px-2 xl:px-4 text-center',
        cellClass: 'py-2 xl:py-3 px-2 xl:px-4 word-break text-center',
        render(bot) {
          return urlFromBot(bot)
        },
      },
      {
        label: 'Version',
        field: 'version',
        headerClass: 'py-2 xl:py-3 px-2 xl:px-4 text-center',
        cellClass: 'py-2 xl:py-3 px-2 xl:px-4 word-break text-center',
      },
      {
        label: 'Actions',
        headerClass: 'py-2 xl:py-3 px-2 xl:px-4 text-center',
        cellClass: 'py-2 xl:py-3 px-2 xl:px-4 text-center',
        render(bot) {
          return (
            <GradientButton
              size='xs'
              border={1}
              className='w-20 rounded-md'
              disabled={!bot.alive || bot.version === latestVersion || !!bot.downloadStatus}
              onClick={() => handleUpgrade(bot)}
            >
              {bot.downloadStatus
                ? Number(bot.downloadStatus.percent) >= 99.9
                  ? 'Restarting'
                  : `${bot.downloadStatus.percent || 0}%`
                : 'Upgrade'}
            </GradientButton>
          )
        },
      },
    ]

    return columns
  }, [latestVersion])

  const data = useMemo(() => {
    const data: UpgradingDataItem[] = bots.map((bot) => {
      const upgradingStatus = allUpgradingStatus[urlFromBot(bot)]
      return { ...bot, downloadStatus: upgradingStatus }
    })
    return data
  }, [bots, allUpgradingStatus])

  useEffect(() => {
    dispatch(getLatestVersionAction())
    const onSuccess = (version: string) => {
      setLatestVersion(version)
    }

    eventEmitter.on(getLatestVersionAction.fulfilled.type, onSuccess)

    return () => {
      eventEmitter.off(getLatestVersionAction.fulfilled.type, onSuccess)
    }
  }, [])

  return (
    <div className='m-auto mt-12 flex w-full max-w-screen-md flex-col gap-8 text-center'>
      <div>
        <div className='text-xl font-bold 2xs:text-2xl xs:text-4xl'>Upgrade your bot(s).</div>
        <label className='mt-3 block'>
          Now the latest version is <span className='text-primary'>{latestVersion}</span>.
        </label>
      </div>
      <GradientWrapper className='rounded-lg' gradientOpacity={0.3}>
        <BaseTable columns={columns} data={data} />
      </GradientWrapper>
    </div>
  )
}

export default UpgradePage
