import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { RejectedValue } from 'src/libs/error.handler'
import eventEmitter from 'src/libs/event.emitter'

import GradientButton from 'src/components/buttons/GradientButton'
import BaseDialog from 'src/components/dialogs/BaseDialog'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'

import { AppDispatch } from 'src/store'
import { addBotAction } from 'src/store/bots'
import { BotReq } from 'src/store/bots/bots.api'

type Props = {
  open: boolean
  onClose: () => void
}

const getEmptyBot = (): BotReq => ({
  name: '',
  protocol: 'http',
  domain: '',
  port: 80,
  gunthyWallet: '',
})

function AddBotDialog({ open, onClose }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const [{ errors, message: errMsg }, setError] = useState<RejectedValue>({})

  const [bot, setBot] = useState<BotReq>(getEmptyBot())

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setBot((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleSave = useCallback(() => {
    dispatch(addBotAction(bot))
  }, [bot])

  const handleClose = useCallback(() => {
    setBot(getEmptyBot())
    setError({})
    onClose()
  }, [onClose])

  const handleFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select()
  }, [])

  useEffect(() => {
    const onFailed = (error: RejectedValue) => {
      setError(error)
    }
    const onSuccess = () => {
      setError({})
      setBot(getEmptyBot())
      onClose()
    }
    eventEmitter.on(addBotAction.rejected.type, onFailed)
    eventEmitter.on(addBotAction.fulfilled.type, onSuccess)

    return () => {
      eventEmitter.off(addBotAction.rejected.type, onFailed)
      eventEmitter.off(addBotAction.fulfilled.type, onSuccess)
    }
  }, [onClose])

  return (
    <BaseDialog open={open} className='w-full max-w-md rounded-lg' onClose={handleClose}>
      <div className='flex flex-col gap-4'>
        <h5>Add your bot</h5>
        <div className='flex flex-col gap-2'>
          <label className='text-subtitle' htmlFor='bot-protocol'>
            Name *
          </label>
          <BaseRoundedInput
            id='bot-name'
            className='rounded-md'
            inputClassName='h-9 text-sm'
            name='name'
            onFocus={handleFocus}
            onChange={handleInputChange}
            value={bot.name}
          />
          {errMsg && <span className='text-error'>{errMsg}</span>}
          {errors?.name && <span className='text-error'>{errors.name[0].message}</span>}
        </div>
        <div className='flex flex-col gap-2'>
          <label className='text-subtitle' htmlFor='bot-protocol'>
            Protocol *
          </label>
          <BaseRoundedInput
            id='bot-protocol'
            className='rounded-md'
            inputClassName='h-9 text-sm'
            name='protocol'
            onFocus={handleFocus}
            onChange={handleInputChange}
            value={bot.protocol}
          />
          {errors?.protocol && <span className='text-error'>{errors.protocol[0].message}</span>}
        </div>
        <div className='flex flex-col gap-2'>
          <label className='text-subtitle' htmlFor='bot-domain'>
            Domain *
          </label>
          <BaseRoundedInput
            id='bot-domain'
            className='rounded-md'
            inputClassName='h-9 text-sm'
            name='domain'
            onFocus={handleFocus}
            onChange={handleInputChange}
            value={bot.domain}
          />
          {errors?.domain && <span className='text-error'>{errors.domain[0].message}</span>}
        </div>
        <div className='flex flex-col gap-2'>
          <label className='text-subtitle' htmlFor='bot-port'>
            Port *
          </label>
          <BaseRoundedInput
            id='bot-port'
            className='rounded-md'
            inputClassName='h-9 text-sm'
            name='port'
            onFocus={handleFocus}
            onChange={handleInputChange}
            value={bot.port}
          />
          {errors?.port && <span className='text-error'>{errors.port[0].message}</span>}
        </div>
        <div className='flex flex-col gap-2'>
          <label className='text-subtitle' htmlFor='bot-gunthyWallet'>
            Gunthy Wallet *
          </label>
          <BaseRoundedInput
            id='bot-gunthyWallet'
            className='rounded-md'
            inputClassName='h-9 text-sm'
            name='gunthyWallet'
            onFocus={handleFocus}
            onChange={handleInputChange}
            onKeyEnter={handleSave}
            value={bot.gunthyWallet}
          />
          {errors?.gunthyWallet && (
            <span className='text-error'>{errors.gunthyWallet[0].message}</span>
          )}
        </div>
        <GradientButton className='mt-4 rounded-full' fontSize='md' onClick={handleSave}>
          Save
        </GradientButton>
      </div>
    </BaseDialog>
  )
}

export default AddBotDialog
