import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { EnvelopeIcon, LockClosedIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import GradientButton from 'src/components/buttons/GradientButton'
import GradientWrapper from 'src/components/wrappers/GradientWrraper'

import { selectEmail } from 'src/store/auth'

import ChangePasswordDialog from './components/ChangePasswordDialog'
import SmtpNotification from './components/SmtpNotification'
import TelegramNotification from './components/TelegramNotification'
import TwoFASetting from './components/TwoFASetting'

function SettingsPage() {
  const [open, setOpen] = useState(false)
  const email = useSelector(selectEmail)
  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  return (
    <div className='container mx-auto mb-8 max-w-7xl'>
      <GradientWrapper
        border={1}
        gradientOpacity={0.3}
        className={clsx('mt-10 overflow-hidden rounded-lg p-4 shadow sm:p-6')}
      >
        <h6 className='text-2xl'>Profile</h6>
        <div className='mt-6 flex flex-col gap-4 md:flex-row md:items-center md:justify-between'>
          <div className='flex flex-col gap-1'>
            <div className='flex items-start gap-2'>
              <EnvelopeIcon className='min-w-[18px]' width={18} />
              <div className='font-bold'>Email</div>
            </div>
            <div className='text-subtitle md:pl-6'>
              This will use this email to login and get notification.
            </div>
          </div>
          <div>{email}</div>
        </div>
      </GradientWrapper>

      <GradientWrapper
        border={1}
        gradientOpacity={0.3}
        className={clsx('mt-10 overflow-hidden rounded-lg p-4 shadow sm:p-6')}
      >
        <h6 className='text-2xl'>Security</h6>
        <div className='mt-6 flex flex-col gap-4 md:flex-row md:items-center md:justify-between'>
          <div className='flex flex-col gap-1'>
            <div className='flex items-start gap-2'>
              <LockClosedIcon className='min-w-[18px]' width={18} />
              <div className='font-bold'>Login Password</div>
            </div>
            <div className='text-subtitle md:pl-6'>
              Login password is used to log in to your account.
            </div>
          </div>
          <div>
            <GradientButton size='sm' className='rounded-md' onClick={handleOpen}>
              Change
            </GradientButton>
          </div>
          <ChangePasswordDialog open={open} onClose={handleClose} />
        </div>

        <TwoFASetting className='mt-6' />
      </GradientWrapper>

      <GradientWrapper
        border={1}
        gradientOpacity={0.3}
        className={clsx('mt-10 overflow-hidden rounded-lg p-4 shadow sm:p-6')}
      >
        <h6 className='text-2xl'>Notifications</h6>

        <SmtpNotification className='mt-6' />

        <TelegramNotification className='mt-6' />
      </GradientWrapper>
    </div>
  )
}

export default SettingsPage
