import React, { useEffect } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import IconButton from '../buttons/IconButton'

import { BaseDivProps } from 'src/types'

export type BaseDialogProps = { open?: boolean; onClose?: () => void } & BaseDivProps

function BaseDialog({ open, className, onClose, children }: BaseDialogProps) {
  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [open])

  return (
    <div
      className={clsx(
        'fixed inset-0 z-50 hidden h-full w-full overflow-y-auto bg-black bg-opacity-90 p-2 sm:p-4',
        { '!flex': open },
      )}
    >
      <div
        className={clsx('relative m-auto bg-mainBg p-6 shadow-xl sm:p-8 lg:p-[46px]', className)}
      >
        <IconButton
          onClick={onClose}
          className='absolute right-[17px] top-[24px] h-[30px] w-[30px] cursor-pointer'
        >
          <XMarkIcon />
        </IconButton>
        {children}
      </div>
    </div>
  )
}

export default BaseDialog
