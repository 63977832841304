import React, { useCallback, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

import GradientWrapper from '../wrappers/GradientWrraper'

import { BaseDivProps } from 'src/types'

export type Column<RowData extends object> = {
  label: string
  field?: keyof RowData
  cellClass?: string
  headerClass?: string
  render?: (row: RowData, rowIndex: number, data: RowData[]) => string | React.ReactNode
}

export type DetailedPanelProps<RowData extends object> = {
  rowData: RowData
  index: number
  data: RowData[]
}

type Props<RowData extends object> = {
  checkable?: boolean
  data: RowData[]
  border?: boolean
  columns: Column<RowData>[]
  DetailedPanel?: React.FC<DetailedPanelProps<RowData>>
  fullDetailedPanel?: boolean
  split?: 0 | 1
  defaultActiveDetailIndex?: number
} & BaseDivProps

function BaseTable<RowData extends object>({
  className,
  checkable,
  columns,
  data,
  border,
  DetailedPanel,
  fullDetailedPanel,
  split = 0,
  defaultActiveDetailIndex = -1,
}: Props<RowData>) {
  const [activeIndex, setActiveIndex] = useState(defaultActiveDetailIndex)

  const handleActive = useCallback(
    (index: number) => () => setActiveIndex((prev) => (prev === index ? -1 : index)),
    [],
  )

  return (
    <GradientWrapper
      border={border ? 1 : 0}
      gradientOpacity={0.3}
      className={clsx('overflow-hidden rounded-lg shadow', className)}
    >
      <table className='min-w-full text-left'>
        <thead>
          <tr className='text-sx font-thin dark:text-subtitle'>
            {DetailedPanel && <td className='w-0 px-3'></td>}
            {columns.map((column, index) => (
              <td className={column.headerClass} key={index}>
                {checkable && index === 0 && <input type='checkbox' />}
                {column.label}
              </td>
            ))}
          </tr>
        </thead>
        <tbody className='text-sm dark:text-title'>
          {data.length > 0 ? (
            data.map((rowData, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr
                  className={clsx({
                    active: rowIndex === 0,
                    'dark:bg-gray-500 dark:bg-opacity-5': rowIndex % 2 == split,
                  })}
                  key={rowIndex}
                >
                  {DetailedPanel && (
                    <td
                      className='cursor-pointer px-3 text-gray-500 hover:text-white'
                      onClick={handleActive(rowIndex)}
                    >
                      <ChevronDownIcon
                        className={clsx('size-4 transition-transform', {
                          'rotate-180': activeIndex === rowIndex,
                        })}
                      />
                    </td>
                  )}
                  {columns.map((column, index) => (
                    <td className={column.cellClass} key={index}>
                      {checkable && <input type='checkbox' />}
                      {column.render && column.render(rowData, rowIndex, data)}
                      {!column.render && column.field && (rowData[column.field] as string)}
                    </td>
                  ))}
                </tr>
                {DetailedPanel && activeIndex === rowIndex && (
                  <tr
                    className={clsx({
                      'dark:bg-gray-500 dark:bg-opacity-5': rowIndex % 2 == split,
                    })}
                  >
                    {!fullDetailedPanel && <td></td>}
                    <td
                      colSpan={columns.length + (fullDetailedPanel ? 1 : 0)}
                      className={clsx('p-4', { 'pl-0': !fullDetailedPanel })}
                    >
                      <DetailedPanel rowData={rowData} index={rowIndex} data={data} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length + (DetailedPanel ? 1 : 0)}
                className='py-5 text-center dark:bg-gray-500 dark:bg-opacity-5'
              >
                No data to display
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </GradientWrapper>
  )
}

export default BaseTable
