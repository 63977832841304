import React from 'react'
import clsx from 'clsx'

import { BaseButtonProps, ElementThemeProps } from 'src/types'

type Props = ElementThemeProps & BaseButtonProps

function BaseButton({
  size = 'md',
  color = 'default',
  variant = 'default',
  fontSize = 'sm',
  className,
  children,
  ...others
}: Props) {
  return (
    <button
      type='button'
      className={clsx(
        'inline-flex items-center justify-center font-normal disabled:pointer-events-none disabled:opacity-50',
        {
          'px-3 py-1.5': size === 'xs',
          'px-4 py-2': size === 'sm',
          'px-6 py-3': size === 'md',
          'px-8 py-4': size === 'lg',
          'px-10 py-5': size === 'xl',
          'px-12 py-6': size === '2xl',

          'text-2xs': fontSize === '2xs',
          'text-xs': fontSize === 'xs',
          'text-sm': fontSize === 'sm',
          'text-md': fontSize === 'md',
          'text-lg': fontSize === 'lg',
          'text-xl': fontSize === 'xl',
          'text-2xl': fontSize === '2xl',

          border: variant === 'outlined',
          'border-gray-800 text-gray-800 hover:border-gray-500 hover:text-gray-500':
            color === 'black' && variant === 'outlined',
          'border-gray-500 text-gray-500 hover:border-gray-800 hover:text-gray-800':
            color === 'gray' && variant === 'outlined',
          'border-teal-500 text-teal-500 hover:border-teal-400 hover:text-teal-400':
            color === 'success' && variant === 'outlined',
          'border-blue-600 text-blue-600 hover:border-blue-500 hover:text-blue-500':
            color === 'info' && variant === 'outlined',
          'border-red-500 text-red-500 hover:border-red-400 hover:text-red-400':
            color === 'error' && variant === 'outlined',
          'border-yellow-500 text-yellow-500 hover:border-yellow-400 hover:text-yellow-400':
            color === 'warning' && variant === 'outlined',
          'border-white/70 text-white/70 hover:border-white hover:text-white':
            color === 'default' && variant === 'outlined',
          'border-gunthy text-gunthy hover:opacity-80':
            color === 'primary' && variant === 'outlined',
        },
        className,
      )}
      {...others}
    >
      {children}
    </button>
  )
}

export default BaseButton
